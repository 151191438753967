import React, { useEffect, useState } from 'react'
import { My } from '../components/My'
import { isMobile } from 'react-device-detect'
import { Modal } from '../components/layout/Modal'
import { PlanTag } from '../components/planTag'

import { subscriptionType } from '../service/plans'
import {
  removeLocalStorage,
  transitionPlanName,
  UserType,
} from '../utils/common'
import useGetUserSubscriptionInfo from '../hooks/query/useGetUserSubscriptionInfo'
import classNames from 'classnames'
import { loadBrandPay } from '@tosspayments/brandpay-sdk'
import useGetBillingMethods from '../hooks/query/brandpay/useGetBillingMethods'
import usePutBillingMethods from '../hooks/query/brandpay/usePutBillingMethods'
import { useGetBillingTransactions } from '../hooks/query/useGetBillingTransactions'
import { useLocation, useNavigate } from 'react-router-dom'
import usePutBillingEnable from '../hooks/query/brandpay/usePutBillingEnable'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import useUserStore from '../store/useUserStore'
import usePostBrandPayTransaction from '../hooks/query/brandpay/usePostBrandPayTransaction'
import { Me } from '../service/user'

export const Subscription = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const clientKey = process.env.REACT_APP_BRAND_PAY_KEY
    ? process.env.REACT_APP_BRAND_PAY_KEY
    : ''
  const itemsPerPage = 10

  const navigate = useNavigate()
  let page = parseInt(params.get('page') || '', 10)
  const [errorText, setErrorText] = useState({
    title: (
      <>
        {' '}
        <p>🚨 구독 재개 실패</p>
      </>
    ),
    subtitle: (
      <>
        <p>
          결제 수단에 문제가 있습니다. <br />
          결제 수단을 추가하거나, 정보를 다시 확인해주세요!
        </p>
      </>
    ),
  })
  const [totalItems, setTotalItems] = useState(0)
  const [defaultPage, setDefaultPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const [isModal, setModal] = useState(false)
  const [isErrorModal, setErrorModal] = useState(false)
  const [brandpay, setBrandpay] = useState<any>(null)

  const { mutate: enableMutate, isPending: enablePending } =
    usePutBillingEnable()

  const {
    mutate: brandPayTransactionMutate,
    isPending: brandPayTransactionPending,
  } = usePostBrandPayTransaction()

  const { data: userSubscriptionInfo, refetch: userSubscriptionInfoRefetch } =
    useGetUserSubscriptionInfo()
  const { data: billingMethods, refetch: billingMethodsFetch } =
    useGetBillingMethods()
  const { mutate: putBillingMethodsMutate } = usePutBillingMethods()
  const { data: billingTransactions, refetch: billingTransactionsRefetch } =
    useGetBillingTransactions(page, itemsPerPage)

  const userInfo: Me = useUserStore((state: any) => state.userInfo)
  const addMethod = async () => {
    brandpay
      .addPaymentMethod('카드')
      .then(async function (methods: any) {
        billingMethodsFetch()
      })
      .catch(function (error: any) {
        if (error.code === 'USER_CANCEL') {
          // 사용자가 결제창을 닫은 경우 에러 처리
        }
      })
  }

  const requestAgreement = () => {
    if (brandpay) {
      brandpay
        .requestAgreement('빌링') // 자동결제 선택 약관 동의 호출
        .then(function (data: any) {
          addMethod()
          // 성공 처리
        })
        .catch(function (error: any) {
          if (error.code === 'USER_CANCEL') {
            console.log('취소')
          }
        })
    }
  }

  const openSetting = async () => {
    if (brandpay) {
      try {
        const res = await brandpay.openSettings()

        // setEnabled((prev) => !prev)
      } catch (e) {
        console.log(e, '실패')
      }
      await billingMethodsFetch()
    }
  }

  useEffect(() => {
    if (userInfo) {
      const userId = userInfo?.userInfo?.id // 고유 사용자 ID
    }
  }, [userInfo])

  const handleEnableSubscription = () => {
    enableMutate('', {
      onSuccess: () => {
        userSubscriptionInfoRefetch()
        setModal((prev) => !prev)
      },
      onError: () => {},
    })
  }

  const handlePayment = () => {
    //약관 동의 체크

    //자동결제 카드 선택 체크
    if (billingMethods) {
      if (billingMethods.filter((el) => el.selected === 1).length <= 0) {
        return setErrorModal((prev) => !prev)
      }
    } else {
      return setErrorModal((prev) => !prev)
    }

    brandPayTransactionMutate(
      {
        planId: userSubscriptionInfo?.lastSubscriptions?.planId,
        planPriceId: userSubscriptionInfo?.lastSubscriptions?.planPriceId,
      },
      {
        onSuccess: (res) => {
          userSubscriptionInfoRefetch()
        },
        onError: (e) => {
          setErrorModal((prev) => !prev)
        },
      }
    )
  }

  const createTable = () => {
    return (
      <>
        <table className="min-w-full border-collapse">
          <thead className="">
            <tr className="border-b  text-[#8F929B] text-left text-[14px] font-normal">
              <td className="py-1 font-normal">결제일</td>
              <td className="py-1">결제수단</td>
              <td className="py-1">금액</td>
              <td className="py-1 text-right">결제 상태</td>
            </tr>
          </thead>
          <tbody>
            {billingTransactions?.data?.map((el, index) => {
              return (
                <>
                  <tr className="text-left text-[#2E343D] border-b ">
                    <td className="py-1">{el.createdAt}</td>
                    <td className="py-1">{el.type}</td>
                    <td className="py-1">
                      {Number(el.amount).toLocaleString()}
                    </td>
                    <td
                      className={classNames('py-1 text-right', {
                        'text-error': el.status !== '성공',
                      })}
                    >
                      {el.status}
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
  const handleBillingMethodDetail = (billingMethodDetailId: number) => {
    putBillingMethodsMutate(billingMethodDetailId, {
      onSuccess: (res) => {
        billingMethodsFetch()
      },
      onError: () => {},
    })
  }
  const handleDate = () => {
    if (
      userSubscriptionInfo?.lastSubscriptions?.subscriptionType ===
      subscriptionType.ANNUAL
    ) {
      return (
        <>
          <p>
            기간 : {userSubscriptionInfo?.lastSubscriptions?.startDate ?? '--'}{' '}
            ~ {userSubscriptionInfo?.lastSubscriptions?.endDate ?? '--'}
          </p>
          <p>다음 결제일 : --</p>
          <p>결제 예정 금액 : ₩0</p>
        </>
      )
    } else if (
      userSubscriptionInfo?.lastSubscriptions?.subscriptionType ===
      subscriptionType.MONTHLY
    ) {
      return (
        <>
          <p>
            기간 : {userSubscriptionInfo?.lastSubscriptions?.startDate ?? '--'}{' '}
            ~ {userSubscriptionInfo?.lastSubscriptions?.endDate ?? '--'}
          </p>
          <p>
            다음 결제일 :{' '}
            {userSubscriptionInfo?.nextAction?.code === 'ENABLE_SUBSCRIPTIONS'
              ? '--'
              : userSubscriptionInfo?.lastSubscriptions?.nextBillingDate}
          </p>
          <p>
            결제 예정 금액 : ₩
            {userSubscriptionInfo?.nextAction?.code === 'ENABLE_SUBSCRIPTIONS'
              ? '0'
              : `${(
                  Number(
                    userSubscriptionInfo?.lastSubscriptions?.nextAmount?.price
                  ) +
                  Number(
                    userSubscriptionInfo?.lastSubscriptions?.nextAmount?.vat
                  )
                ).toLocaleString()}`}
          </p>
        </>
      )
    } else {
      return (
        <>
          <p>기간 : - ~ -</p>
          <p>
            다음 결제일 :{' '}
            {userSubscriptionInfo?.nextAction?.code === 'ENABLE_SUBSCRIPTIONS'
              ? '--'
              : userSubscriptionInfo?.lastSubscriptions?.nextBillingDate}
          </p>
          <p>
            결제 예정 금액 : ₩
            {userSubscriptionInfo?.nextAction?.code === 'ENABLE_SUBSCRIPTIONS'
              ? '0'
              : `${(
                  Number(
                    userSubscriptionInfo?.lastSubscriptions?.nextAmount?.price
                  ) +
                  Number(
                    userSubscriptionInfo?.lastSubscriptions?.nextAmount?.vat
                  )
                ).toLocaleString()}`}
          </p>
        </>
      )
    }
  }
  const renderPageNumbers = () => {
    const pagesPerBlock = 10
    const pageNumbers = []
    const currentBlock = Math.floor((currentPage - 1) / pagesPerBlock)
    const startPage = currentBlock * pagesPerBlock + 1
    const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          className={classNames(
            `flex items-center justify-center w-[32px] h-[32px] border-[1px]  font-bold text-[14px] rounded-[4px]`,
            {
              'border-main text-main': currentPage === i,
              'border-[#DFE3E8]': currentPage !== i,
            }
          )}
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={currentPage === i}
        >
          {i}
        </button>
      )
    }

    return pageNumbers
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  const handlePageEndChange = (page: number) => {
    setCurrentPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', page.toString())
    params.set('pageSize', itemsPerPage.toString())
    navigate({ search: params.toString() })
  }

  useEffect(() => {
    const page = parseInt(params.get('page') || '', 10)

    setCurrentPage(page)
    billingTransactionsRefetch()
  }, [location.search])

  useEffect(() => {
    if (billingTransactions) {
      setTotalItems(billingTransactions.totalCount)
    }
  }, [billingTransactions])

  useEffect(() => {
    userSubscriptionInfoRefetch()
    let page = parseInt(params.get('page') || '', 10)
    if (!page || isNaN(page) || page < 1) {
      page = defaultPage
      params.set('page', page.toString())
      params.set('pageSize', itemsPerPage.toString())
      navigate({ search: params.toString() })
    }
  }, [])

  useEffect(() => {
    async function fetchBrandpay() {
      try {
        const brandpay = await loadBrandPay(
          clientKey,
          userSubscriptionInfo?.customerKey ?? '',
          {
            redirectUrl: process.env.REACT_APP_REDIRECT_URL,
          }
        )

        setBrandpay(brandpay)
      } catch (error) {
        console.error('Error fetching brandpay:', error)
      }
    }

    fetchBrandpay()
  }, [userSubscriptionInfo])

  return (
    <div className=" md:w-[816px] w-[375px] min-h-[100vh] my-0 mx-auto flex flex-col items-center gap-6 px-4">
      <div className="md:w-full w-[343px] flex flex-col gap-6">
        {!isMobile ? (
          <>
            <My>
              <p
                onClick={() => {
                  navigate('/my-payments')
                }}
              >
                내 플랜
              </p>
              {'>'}
              <p>구독 관리</p>
            </My>
            <p className="font-bold text-[48px] text-center">구독 관리</p>
          </>
        ) : (
          <>
            <div className="w-[335px] font-bold text-[24px] relative flex justify-center">
              <p className="">구독 관리</p>
              <p
                className=" absolute left-0"
                onClick={() => {
                  navigate('/my-payments')
                }}
              >
                {'<'}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="w-full  font-bold flex flex-col gap-4">
        <p className=" text-[24px]">사용중인 플랜</p>
        <p className="flex gap-1 ">
          {(!userInfo?.billing?.isActive &&
            userSubscriptionInfo?.subscriptions) ||
          (userInfo?.billing?.isActive &&
            userSubscriptionInfo?.lastSubscriptions) ? (
            <>
              {userInfo?.userType !== UserType.체험 ? (
                <>
                  {' '}
                  <span>
                    {userSubscriptionInfo?.lastSubscriptions?.planName}{' '}
                    {
                      userSubscriptionInfo?.lastSubscriptions
                        ?.subscriptionTypeLabel
                    }
                  </span>
                </>
              ) : (
                <>
                  <span>무료 체험</span>
                </>
              )}

              <PlanTag
                planName={
                  transitionPlanName[
                    userSubscriptionInfo?.lastSubscriptions
                      ?.planName as keyof typeof transitionPlanName
                  ]
                }
                className=""
              />
              {userSubscriptionInfo?.nextAction?.code ===
                'RE_SUBSCRIPTIONS' && (
                <span className="text-error font-[400]">
                  다음 라운드 결제 실패
                </span>
              )}
            </>
          ) : (
            <>
              <span>사용 중인 플랜이 없어요</span>
            </>
          )}
        </p>
        {((!userInfo?.billing?.isActive &&
          userSubscriptionInfo?.subscriptions) ||
          (userInfo?.billing?.isActive &&
            userSubscriptionInfo?.lastSubscriptions)) && (
          <div className=" font-normal">{handleDate()}</div>
        )}
        {userSubscriptionInfo?.nextAction?.code === 'ENABLE_SUBSCRIPTIONS' ? (
          <>
            {' '}
            <button
              className="md:w-[400px] w-full h-[34px] bg-[#4360EC] rounded-[10px] text-white font-bold text-[14px]"
              onClick={handleEnableSubscription}
            >
              구독 해지 취소{' '}
              <span className="font-[400]">(구독을 유지해요)</span>
            </button>
          </>
        ) : (
          <>
            <div className="md:w-[400px] w-full">
              <PromotionCircle userInfo={userInfo} />
            </div>
          </>
        )}
        {userSubscriptionInfo?.nextAction?.code === 'DISABLE_SUBSCRIPTIONS' && (
          <button
            className="text-[#8F929B] text-[14px] underline md:text-left text-center"
            onClick={() => {
              navigate('/cancel-subscription')
            }}
          >
            구독 해지
          </button>
        )}
      </div>
      <div className="payment-method md:w-[816px] w-full flex flex-col my-8 gap-4">
        <p className="font-bold text-[24px] text-left">결제 수단</p>
        <p className="flex gap-1 items-center">
          <img
            src="assets/img/logo/fastcut-pay.jpg"
            alt="pay"
            className="w-[21px] h-[21px]"
          />
          <span className="font-[700] text-main">Pay</span>
          <span className="font-[700] ">패스트컷페이</span>
          <img
            src="assets/img/logo/toss.png"
            alt="logo"
            className="w-[137px] h-[20px]"
          />
        </p>
        <div className="flex gap-2 overflow-x-auto">
          {billingMethods?.map((el) => {
            return (
              <>
                <button
                  className={classNames(
                    'w-full min-w-[300px] rounded-[10px] flex items-center  h-[84px]  px-3   gap-[10px]',
                    {
                      'border-[1px] border-[#D9DEE8]': !el.selected,
                      'border-[2px] border-main': el.selected,
                    }
                  )}
                  onClick={() => {
                    handleBillingMethodDetail(el.id)
                  }}
                >
                  <img
                    src={el.methodIconUrl}
                    alt="icon"
                    className="w-[40px] h-[64px]"
                  />
                  <div className="flex flex-col items-start">
                    <p className="font-bold text-[14px]">
                      {el.methodName}({el.methodNumber})
                    </p>
                    <p className=" text-[14px]">카드</p>
                  </div>
                </button>
              </>
            )
          })}
          <button
            className="w-full min-w-[300px] rounded-[10px] flex items-center border-[1px] border-[#2E343D] h-[84px] flex-col justify-center"
            onClick={() => {
              if (billingMethods) {
                const methodLen = billingMethods?.length
                if (methodLen <= 0) {
                  requestAgreement()
                } else {
                  addMethod()
                }
              }
            }}
          >
            <p className="flex gap-1 items-center">
              <img
                src="assets/img/logo/fastcut-pay.jpg"
                alt="pay"
                className="w-[21px] h-[21px]"
              />
              <span className="font-[700] text-main">Pay</span>
              <span className="font-[700] ">+</span>
            </p>
            <p>패스트컷페이를 추가하고 빠르게 결제하세요</p>
          </button>
        </div>
        {userInfo?.billing?.isActive &&
          userSubscriptionInfo?.nextAction?.code === 'RE_SUBSCRIPTIONS' && (
            <>
              <span className="text-error font-[400]">
                결제 수단을 추가하고 아래 구독 재개 버튼을 눌러주세요!
              </span>
              <button
                className="bg-[#4360EC] rounded-[10px] md:w-[400px] w-[343px] h-[34px] font-bold text-white text-[14px]"
                onClick={handlePayment}
              >
                구독 재개
              </button>
            </>
          )}
      </div>

      <div className="payment-method md:w-[816px] flex flex-col gap-4 w-full">
        <p className="font-bold text-[24px] text-left">결제 내역</p>
        {createTable()}
        <div className="page flex flex-col gap-[16px] justify-center items-center mt-4">
          <div className="flex justify-center gap-[4px] ">
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageEndChange(1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left_end.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src="assets/img/icon_arrow_left.png" alt="icon" />
            </button>
            {renderPageNumbers()}
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src="assets/img/icon_arrow_right.png" alt="icon" />
            </button>
            <button
              className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-[#DFE3E8] font-bold text-[14px] disabled:bg-[#EEF1F7] rounded-[4px]"
              disabled={currentPage === totalPages}
              onClick={() => {
                handlePageEndChange(totalPages)
              }}
            >
              <img src="assets/img/icon_arrow_right_end.png" alt="icon" />
            </button>
          </div>
        </div>
      </div>
      {isModal && (
        <ConfirmModal
          isModal={isModal}
          setModal={setModal}
          handleEnableSubscription={handleEnableSubscription}
        />
      )}

      {isErrorModal && (
        <Modal isModal={isErrorModal}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setErrorModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

const ConfirmModal = (props: any) => {
  const { isModal, setModal, handleEnableSubscription } = props
  return (
    <Modal isModal={isModal}>
      <div className="bg-white w-[335px] h-[184px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">구독 해지 취소</p>
          <div className="flex flex-col text-[14px] leading-[22px]">
            구독 해지를 취소하고 <br />
            기존 플랜으로 구독을 유지합니다.
          </div>
        </div>
      </div>
      <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-full h-full bg-main rounded-b-[40px] border-b border-main font-bold flex items-center justify-center"
          onClick={() => {
            setModal(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
