import { ShadowBox } from '../components/ShadowBox'
import { Input } from '../components/atom/Input'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import usePostList from '../hooks/query/userPostList'
import { Modal } from '../components/layout/Modal'
import { useNavigate } from 'react-router-dom'
import { useGetProducts } from '../hooks/query/useGetProducts'
import { CircularCheckbox } from '../components/CircularCheckbox'
import '../App.css'
import useGetBgm from '../hooks/query/useGetBgm'
import useGetTransition from '../hooks/query/useGetTransition'
import useGetVoice from '../hooks/query/useGetVoice'
import { useGetLongTypeTone } from '../hooks/query/useGetTone'
import useGetPrompt from '../hooks/query/useGetPrompt'
import TooltipIcon from '../components/TooltipIcon'
import { Usage } from '../service/user'
import {
  getLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  PREV_THUMBNAIL_INIT,
  removeLocalStorage,
  renderPaybackVideo,
  saveLocalStorage,
  showYoutubeAuth,
  THUMBNAIL_INFO,
  transitionPlanName,
  UserType,
} from '../utils/common'

import { CSSTransition } from 'react-transition-group'
import useGetThumbnail from '../hooks/query/useGetThumbnail'
import { thumbnailOptions, ThumbnailOptionType } from '../dummy/data'
import { GuideToUse } from '../components/GuideToUse'

import { Thumbnail, ThumbnailInfo } from '../components/thumbnail/Thumbnail'
import { MobileThumbnail } from '../components/thumbnail/MobileThumbnail'

import { GuideModal } from '../components/freePlan/GuideModal'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import { PlanTag } from '../components/planTag'

import { useGetSubtitleLong } from '../hooks/query/useGetSubtitle'
import { longFormCreateBtnClick } from '../utils/metaAd'

import { PlanPromotionBanner } from '../components/affiliate/PlanPromotionBanner'

import { FreeButton } from '../components/atom/FreeButton'
import { usePostExperiencePlan } from '../hooks/query/usePostExperiencePlan'
import { isMobile } from 'react-device-detect'
import {
  EmptyPlan,
  FreePlan,
  LightPlan,
  ProAndPremiumPlanAnnual,
  ProAndPremiumPlanMonthly,
} from '../components/PlanDashBoard/PlanDashBoard'
import useGetMePlan from '../hooks/query/useGetMePlan'
import classNames from 'classnames'
import { ThumbnailDiv } from '../components/thumbnail/ThumbnailDiv'
import { FormValues } from '../utils/type'
import { PlanPriceModal } from '../components/payment/PlanPriceModal'
import useUserStore from '../store/useUserStore'
import useUsageStore from '../store/useUsageStore'
import { FreePlanBanner } from '../components/freePlan/Banner'
import { WithShortsTooltip } from '../components/video/WithShortsTooltip'
import { FailWithShortModal } from '../components/video/FailWithShortModal'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { useMutateMyPoint } from '../hooks/query/point/useMutateMyPoint'
import { Spinner } from '../components/atom/Spinner'
import { TransitionModal } from '../components/video/TransitionModal'
import { OutroFactor, ThumbnailType, TransitionFactor } from '../service/list'
import { VideoComponent } from '../components/video/VideoComponent'
import { IntroTooltip } from '../components/video/IntroTooltip'
import { ThumbnailImgComponent } from '../components/shortvideo/ThumbnailImgComponent'
import { Transition } from '../components/video/factor/Transition'
import { OutroDisplay } from '../components/video/factor/OutroDisplay'
import { PriceDisplay } from '../components/video/factor/PriceDisplay'
import { IntroDisplay } from '../components/video/factor/IntroDisplay'
import useGetOutro from '../hooks/query/useGetOutro'
import useGetAIPromptGroup from '../hooks/query/useGetAIPromptGroup'
import { BgmDisplay } from '../components/video/factor/BgmDisplay'
import { AiScriptDisplay } from '../components/video/factor/AiScriptDisplay'
import useGetVoiceTag from '../hooks/query/useGetVoiceTag'
import { StickerDisplay } from 'components/video/factor/StickerDisplay'
import useGetSticker from 'hooks/query/useGetSticker'
import useGetStickerLocation from 'hooks/query/useGetStickerLocation'
import { IncludeAddDisplay } from 'components/video/factor/IncludeAddDisplay'

export const Video = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const isTime = urlParams.get('time')
  const guide_sample = getLocalStorage('guide_sample')
  const [isSample, setSample] = useState(guide_sample)
  // bgm / voice / transition 불러오기
  const URL_PATTERN =
    /^https:\/\/(s\.click\.aliexpress\.com|link\.coupang\.com\/a)/

  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/
  const MAIN_TITLE_PATTERN = /^(?!\s*$)[^\s<>\uD800-\uDFFF]+$/

  const videoEditorData = getLocalStorage('videoEditorData')

  const { mutate: myPointMutate, mutateAsync: myPointMutateAsync } =
    useMutateMyPoint()
  const { data: bgmList, refetch: bgmRefetch } = useGetBgm()
  const { data: aiPromptGroup, refetch: aiPromptGroupRefetch } =
    useGetAIPromptGroup()
  const { data: transitionList, refetch: transitonRefetch } = useGetTransition()
  const { data: outroList, refetch: outroRefetch } = useGetOutro()
  const { data: voiceList, refetch: voiceRefetch } = useGetVoice()
  const { data: stickerList, refetch: stickerRefetch } = useGetSticker()
  const { data: stickerLocationList, refetch: stickerLocationRefetch } =
    useGetStickerLocation()

  const { data: thumbnailList, refetch: thumbnailRefetch } = useGetThumbnail()
  const { data: voiceTags } = useGetVoiceTag()
  const { data: subTitleList } = useGetSubtitleLong()
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()

  // sample가져오기
  const { data: products } = useGetProducts()

  const navigate = useNavigate()
  const [isFreePlanModal, SetFreePlanModal] = useState<boolean>(false)

  const userInfo = useUserStore((state: any) => state.userInfo)
  const refetch = useUserStore((state: any) => state.refetch)
  const usageRefetch = useUsageStore((state: any) => state.usageRefetch)

  const { data: userPlanInfo, refetch: planRefetch } = useGetMePlan()

  const { isPending, mutate } = usePostList()
  const [isGuideModal, setGuideModal] = useState<boolean>(false)
  const [isRequest, setRequest] = useState<boolean>(true)
  const [isMobileTModal, setMobileTModal] = useState<boolean>(false)
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [isYoutube, setIsYoutube] = useState<boolean>(false)
  const [isThumbnailModal, setThumbnailCheckModal] = useState<boolean>(false)
  const [prevThumbnail, setPrevThumbnail] = useState(PREV_THUMBNAIL_INIT)
  const [editThumbnail, setEditThumbnail] = useState<boolean>(false)
  const [isTransitionModal, setTransitionModal] = useState<boolean>(false)
  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailType>()
  const [error, setError] = useState({
    products: [
      { link: false, product: false },
      { link: false, product: false },
      { link: false, product: false },
    ],
    mainTitle: false,
    thumbnail: false,
  })
  const [selectedTransitionIndex, setSelectedTransitionIndex] =
    useState<number>(0)
  const [copyTransitionList, setCopyTransitionList] = useState<
    TransitionFactor[] | []
  >([])

  const [copyOutroList, setCopyOutroList] = useState<OutroFactor[] | []>([])
  // const [thumbnailInfo, setThumbnailInfo] =
  //   useState<ThumbnailInfo>(THUMBNAIL_INFO)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isOpen, setIsOpen] = useState(true)
  const [isImageDivOpen, setImageDivOpen] = useState<boolean[]>(
    new Array(3).fill(false)
  )
  const [errorText, setErrorText] = useState<string>('')

  const [tooltipStatus, setTooltipStatus] = useState<boolean>(false)
  const [withShortsStatus, setWithShortsStatus] = useState<boolean>(false)
  const [isShortSubtitle, setShortSubtitle] = useState<boolean>(false)
  const [introTooltipStatus, setIntroTooltipStatus] = useState<boolean>(false)
  const [usage, setUsage] = useState<Usage>()

  const [mobileStatus, setMobileStatus] = useState<boolean>(false)
  const [isPlanPriceModal, setPlanPriceModal] = useState<boolean>(false)
  const [isFailWithShortModal, setFailWithShortModal] = useState<boolean>(false)

  // inputRef
  const mainTitleInputRef = useRef<null | HTMLInputElement>(null)
  const thumbNailDivRef = useRef<null | HTMLDivElement>(null)
  const subtitleDivRef = useRef<null | HTMLDivElement>(null)
  const transitionDivRef = useRef<null | HTMLDivElement>(null)
  const productsRef = useRef<HTMLInputElement | null>(null)
  const [uploadImages, setUploadImages] = useState<File[]>([])
  const [selectedUploadImages, setSelectedUploadImages] = useState(
    new Array(uploadImages.length).fill(true)
  )

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      mainTitle: '',
      voiceId: 0,
      bgmId: 0,
      transitionId: 0,
      aiToneTypeId: 0,
      aiPromptTypeId: 0,
      thumbnailId: 0,
      subtitleId: 0,
      outroId: 0,
      products: [
        { link: '', productName: '', files: [], selectedFiles: [] },
        { link: '', productName: '', files: [], selectedFiles: [] },
        { link: '', productName: '', files: [], selectedFiles: [] },
      ],
      thumbnailInfo: {
        channelName: '',
        title1: '',
        title2: '',
        title3: '',
        thumbnailFile: [],
      },
      withShort: false,
      includeIntro: false,
      includePrice: false,
      includeAdd: false,
      stickerId: 0,
      stickerLocation: 'lt',
      shortInfo: { subtitleFlag: 0 },
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
    rules: {
      maxLength: 5,
    },
  })

  const initialThumbnailOption: ThumbnailOptionType = {
    name: 'thumbnail_01',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
    ],
  }
  const [thumbnailType, setThumbnailType] = useState<ThumbnailOptionType>(
    initialThumbnailOption
  )
  const translatePlanName = (planName: string) => {
    let tPlanName = ''
    switch (planName) {
      case '라이트':
        tPlanName = 'LIGHT'
        break
      case '프로':
        tPlanName = 'PRO'
        break
      case '프리미엄':
        tPlanName = 'PREMIUM'
        break
    }
    return tPlanName
  }

  const paymentFreePlan = () => {
    if (!userPlanInfo && userInfo?.experiencedUser) {
      return setPlanPriceModal(true)
    }
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo && userInfo?.userType === UserType.체험) {
      return
    }

    experiencePlanMutate('', {
      onSuccess: (data) => {
        refetch()
        planRefetch()
      },
      onError: (e) => {
        console.log(e)
      },
    })
  }

  const scrollToRef = useRef<HTMLDivElement>(null)
  const handleScroll = () => {
    if (scrollToRef.current) {
      // scrollIntoView를 사용해 해당 위치로 스크롤
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const onSubmit = async (data: FormValues) => {
    if (!userInfo) {
      return navigate('/sign-up')
    }
    setRequest((prev) => !prev)
    if (window.gtag) {
      // Event snippet for 영상생성요청클릭 conversion page

      window.gtag('event', 'conversion', {
        send_to: 'AW-677452397/OM8ECK_B3IwaEO20hMMC',
        value: 1.0,
        currency: 'KRW',
      })
    } else {
      console.error('gtag is not defined')
    }
    let {
      mainTitle,
      products,
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      thumbnailId,
      subtitleId,
      thumbnailInfo,
      withShort,
      includeIntro,
      includePrice,
      includeAdd,
      outroId,
      stickerId,
      stickerLocation,
      shortInfo,
    } = data

    const prevInfo = {
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      subtitleId,
      withShort,
      includeIntro,
      includePrice,
      includeAdd,
      outroId,
      stickerId,
      stickerLocation,
      shortInfo,
    }
    saveLocalStorage('videoEditorData', prevInfo)
    mainTitle = mainTitle.trim()
    // setError({
    //   products: [
    //     { link: false, product: false },
    //     { link: false, product: false },
    //     { link: false, product: false },
    //   ],
    //   mainTitle: false,
    //   thumbnail: false,
    // })
    const products_trim = []
    for (const product of products) {
      if (product.link && product.productName) {
        products_trim.push({
          link: product.link.trim(),
          productName: product.productName.trim(),
          files: product.selectedFiles,
        })
      }
    }
    if (withShort) {
      // true일 경우 캐시가 충분한지 확인해야 함
      try {
        const myPointData = await myPointMutateAsync()

        const productLen = products_trim?.length || 0
        const productShortCash = productLen * 300
        const currentMyCash = myPointData?.totalPoint ?? 0

        // 캐시가 부족한 경우 모달 표시
        if (currentMyCash < productShortCash) {
          setRequest((prev) => !prev)
          return setFailWithShortModal((prev) => !prev)
        }
      } catch (error) {
        console.error('myPoint 설정에 오류가 생겼습니다.', error)
      }
    }
    if (
      thumbnailId === 0 ||
      (thumbnailInfo.title1 === '' &&
        thumbnailInfo.title2 === '' &&
        thumbnailInfo.title3 === '')
    ) {
      setErrorText('썸네일 스타일과 썸네일 내용을 입력해주세요.')
      setRequest((prev) => !prev)
      if (thumbNailDivRef.current) {
        thumbNailDivRef.current.focus()
        thumbNailDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
      setEssentialCheck(true)
      return
    }

    if (
      Number(watch('aiPromptTypeId')) === 0 ||
      Number(watch('aiToneTypeId')) === 0 ||
      Number(watch('voiceId')) === 0
    ) {
      setErrorText('AI 생성 대본 효과를 선택해주세요.')
      setRequest((prev) => !prev)
      if (thumbNailDivRef.current) {
        thumbNailDivRef.current.focus()
        thumbNailDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      setEssentialCheck(true)
      return
    }

    if (Number(watch('bgmId')) === 0) {
      setErrorText('배경음악을 선택해주세요.')
      setRequest((prev) => !prev)
      if (thumbNailDivRef.current) {
        thumbNailDivRef.current.focus()
        thumbNailDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      setEssentialCheck(true)
      return
    }
    if (Number(watch('transitionId')) === 0) {
      setErrorText('화면 전환 효과를 선택해주세요.')
      setRequest((prev) => !prev)
      if (transitionDivRef.current) {
        transitionDivRef.current.focus()
        transitionDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      setEssentialCheck(true)
      return
    }

    if (Number(watch('outroId')) === 0) {
      setErrorText('영상 마지막 구독 & 좋아요 요청 화면을 선택해주세요.')
      setRequest((prev) => !prev)
      if (transitionDivRef.current) {
        transitionDivRef.current.focus()
        transitionDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
      setEssentialCheck(true)
      return
    }

    const videoInfo = {
      mainTitle,
      products: products_trim,
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      thumbnailId,
      subtitleId,
      thumbnailInfo,
      check: isRequest,
      withShort,
      includeIntro,
      includePrice,
      includeAdd,
      outroId,
      stickerId,
      stickerLocation,
      shortInfo,
    }

    const formData = new FormData()

    // Append the non-file data
    // JSON 객체를 FormData로 변환하여 추가하는 재귀 함수
    function appendFormData(
      data: string | object | null,
      rootName = '',
      formData = new FormData()
    ) {
      if (
        typeof data === 'object' &&
        data !== null &&
        !(data instanceof File)
      ) {
        Object.entries(data).forEach(([key, value]) => {
          const formKey = rootName ? `${rootName}[${key}]` : key
          if (
            typeof value === 'object' &&
            value !== null &&
            !(value instanceof File)
          ) {
            appendFormData(value, formKey, formData) // 재귀적으로 객체 추가
          } else {
            formData.append(formKey, value)
          }
        })
      } else {
        // @ts-ignore
        formData.append(rootName, data)
      }
      return formData
    }

    // 객체 키들을 FormData에 추가
    Object.keys(videoInfo).forEach((key) => {
      if (key !== 'products') {
        // JSON 객체인 경우 재귀적으로 추가, 그렇지 않으면 그대로 추가
        // @ts-ignore
        if (typeof videoInfo[key] === 'object' && videoInfo[key] !== null) {
          // @ts-ignore
          appendFormData(videoInfo[key], key, formData)
        } else {
          // @ts-ignore
          formData.append(key, videoInfo[key])
        }
      }
    })

    // Append the product URLs and files
    videoInfo.products.forEach((product, productIndex) => {
      formData.append(`products[${productIndex}][link]`, product.link)
      formData.append(
        `products[${productIndex}][productName]`,
        product.productName
      )

      // Append the files for each product
      product.files.forEach((file: string | Blob, fileIndex: any) => {
        // 실제 파일 객체로 변환되어야 함
        if (file instanceof File) {
          formData.append(
            `products[${productIndex}][files][${fileIndex}]`,
            file
          )
        }
      })
    })

    mutate(formData, {
      onSuccess: () => {
        longFormCreateBtnClick()
        setRequest(true)
        setSaveSuccessModal((prev) => !prev)
        removeLocalStorage(LOCAL_THUMBNAIL_INFO)
        if (productsRef.current) {
          productsRef.current.focus()
          productsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      },
      onError: (e) => {
        //@ts-ignore
        const serverErrorMsg = e.response?.data?.message || e.message
        console.log(serverErrorMsg)
        setRequest(true)
        setEssentialCheck(true)
        setErrorText(serverErrorMsg)
        console.log(e.message)
      },
    })
  }

  const handleImageDivOpen = (index: number) => {
    // 해당되는 index의 bool을 교체해줘야해
    const updateImageDivOpen = isImageDivOpen.map((el, idx) =>
      idx === index ? !el : el
    )
    setImageDivOpen(updateImageDivOpen)
  }

  const toggleContent = () => {
    setIsOpen(!isOpen)
  }

  const [videoPlaying, setVideoPlaying] = useState<boolean[]>(
    new Array(transitionList?.length).fill(false)
  )

  const [voicePlaying, setVoicePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )
  const [voicePrePlaying, setVoicePrePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )

  const [bgmPlaying, setBgmPlaying] = useState<boolean[]>(
    new Array(bgmList?.length).fill(false)
  )

  const [thumbnailStatus, setThumbnailStatus] = useState<boolean[]>(
    new Array(thumbnailList?.length).fill(false)
  )

  const [toneTooltip, setToneTooltip] = useState<boolean[]>([])
  const [promptStatus, setPromptStatus] = useState<boolean[]>([])

  //여러개 tooltip 관리

  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([])

  const handleThumbnail = (index: number) => {
    const updatedThumbnailState = thumbnailStatus.map((value, idx) =>
      idx === index ? true : false
    )
    setThumbnailStatus(updatedThumbnailState)
    setThumbnailCheckModal(false)
  }

  const handleMouseLeave = (index: number) => {
    if (videoRefs.current[index]) {
      try {
        videoRefs.current[index]!.currentTime = 1
        videoRefs.current[index]!.pause()
      } catch (e) {
        console.log(e, 'not pause video index:', index)
      }
    }
  }

  // bgm / transition / voice handle function 부분
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const voicePreRefs = useRef<(HTMLAudioElement | null)[]>([])
  const bgmRefs = useRef<(HTMLAudioElement | null)[]>([])

  const handleBgmPlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = bgmPlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setBgmPlaying(updatedPlayingState)

    if (bgmRefs.current[index]) {
      if (status) {
        bgmRefs.current[index]?.play()
      } else {
        bgmRefs.current[index]?.pause()
      }
    }
  }

  const handleSelectedTransition = (behavior: boolean) => {
    if (videoRef.current && behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.muted = true
          videoRef.current!.currentTime = 0
          videoRef.current?.play().catch((error) => {
            console.warn('Playback failed:', error)
          })
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    } else if (videoRef.current && !behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.currentTime = 1
          videoRef.current?.pause()
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    }
  }

  const handleVideoEnded = (index: number) => {
    const updatedPlayingState = [...videoPlaying]
    updatedPlayingState[index] = false
    setVideoPlaying(updatedPlayingState)
  }

  const handlePlayButtonClick = (index: number) => {
    const updatedPlayingState = videoPlaying.map((value, idx) =>
      idx === index ? true : false
    )
    setVideoPlaying(updatedPlayingState)
  }

  const isFreePlanOrUser = () => {
    if (!userInfo) {
      return (
        <FreePlanBanner
          SetFreePlanModal={SetFreePlanModal}
          paymentFreePlan={paymentFreePlan}
        />
      )
    } else if (
      userInfo &&
      userInfo?.userType === UserType.USER &&
      !userInfo?.experiencedUser
    ) {
      return (
        <FreePlanBanner
          SetFreePlanModal={SetFreePlanModal}
          paymentFreePlan={paymentFreePlan}
        />
      )
    }
    return null
  }

  const handleLoadedMetadata = (index: number) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index]!.currentTime = 1
      videoRefs.current[index]!.pause()
    }
  }
  const handleInitData = () => {
    // 신경써야할 부분 기존의 localStorage에 저장된 신경쓰기

    setValue('mainTitle', '')
    setValue('voiceId', 0)
    setValue('bgmId', 0)
    setValue('transitionId', 0)
    setValue('aiToneTypeId', 0)
    setValue('aiPromptTypeId', 0)
    setValue('aiPromptTypeId', 0)
    setValue('thumbnailId', 0)
    setValue('subtitleId', 0)
    setValue('products.0', {
      link: '',
      productName: '',
      files: [],
      selectedFiles: [],
    })
    setValue('products.1', {
      link: '',
      productName: '',
      files: [],
      selectedFiles: [],
    })
    setValue('products.2', {
      link: '',
      productName: '',
      files: [],
      selectedFiles: [],
    })

    setValue('thumbnailInfo', {
      channelName: '',
      title1: '',
      title2: '',
      title3: '',
      thumbnailFile: [],
    })

    setThumbnailStatus(new Array(thumbnailList?.length).fill(false))
    setVideoData()
  }

  const setVideoData = () => {
    if (videoEditorData) {
      const {
        voiceId,
        bgmId,
        transitionId,
        aiPromptTypeId,
        aiToneTypeId,
        subtitleId,
        withShort,
        includeIntro,
        includePrice,
        outroId,
        stickerLocation,
        stickerId,
        includeAdd,
      } = videoEditorData

      if (aiPromptTypeId) {
        setValue('aiPromptTypeId', aiPromptTypeId)
        if (aiPromptGroup) {
          const selectedPromptGroup = aiPromptGroup?.filter(
            (el) => el.id === aiPromptTypeId
          )
          if (aiToneTypeId && selectedPromptGroup[0]) {
            const selectedTone = selectedPromptGroup[0]?.toneGroup?.filter(
              (el) => el.id === aiToneTypeId
            )
            setToneTooltip(
              new Array(selectedPromptGroup[0]?.toneGroup?.length).fill(false)
            )
            setValue('aiToneTypeId', selectedTone[0]?.id ?? selectedTone[0]?.id)
          }
        }
      }

      if (voiceId) {
        const [voiceInfo] = voiceList.filter((el) => el.id === voiceId)

        if (voiceInfo?.usable) {
          setValue('voiceId', voiceId)
        } else {
          setValue('voiceId', voiceList[0].id)
        }
      }
      if (transitionId) {
        if (transitionList) {
          const [transitionInfo] = transitionList?.filter(
            (el) => el.id === transitionId
          )
          if (transitionInfo?.usable) {
            setValue('transitionId', transitionId)
          } else {
            const id = transitionList.filter((el) => el.default === 1)[0]?.id
            setValue('transitionId', id)
          }
        }
      }
      if (outroId) {
        setValue('outroId', outroId)
      }
      if (bgmId) {
        setValue('bgmId', bgmId)
      }
      if (subtitleId) {
        setValue('subtitleId', subtitleId)
      } else {
        if (subTitleList) {
          setValue('subtitleId', subTitleList[0].id)
        } else {
          setValue('subtitleId', 1)
        }
      }
      if (withShort) {
        setValue('withShort', withShort)
      }
      if (includeIntro) {
        setValue('includeIntro', includeIntro)
      }
      if (includePrice) {
        setValue('includePrice', includePrice)
      }
      if (includeAdd) {
        setValue('includeAdd', includeAdd)
      }
      if (stickerId) {
        if (stickerList) {
          const [ss] = stickerList.filter((el) => el?.id === stickerId)
          const id = stickerList.filter((el) => el.default === 1)[0]?.id

          if (ss?.usable) {
            setValue('stickerId', stickerId)
          } else {
            setValue('stickerId', id ?? stickerList[0].id)
          }
        }
        setValue('stickerId', stickerId)
      }
      if (stickerLocation) {
        setValue('stickerLocation', stickerLocation)
      }
    } else {
      if (aiPromptGroup) {
        setPromptStatus(new Array(aiPromptGroup.length).fill(false))
        const [init] = aiPromptGroup.filter((el) => el.default === 1)
        setValue('aiPromptTypeId', init.id)
        if (init?.toneGroup) {
          setToneTooltip(new Array(init.toneGroup.length).fill(false))
          setValue('aiToneTypeId', init.toneGroup[0].id)
        }
      }

      if (voiceList) {
        setValue('voiceId', voiceList[0].id)
      }
      if (transitionList) {
        const id = transitionList.filter((el) => el.default === 1)[0]?.id
        setValue('transitionId', id ?? 0)

        setCopyTransitionList(transitionList)
      }
      if (outroList) {
        const id = outroList.filter((el) => el.default === 1)[0]?.id
        setValue('outroId', id ?? 0)
        setCopyOutroList(outroList)
      }
      if (bgmList) {
        setValue('bgmId', bgmList[0].id)
      }
      if (subTitleList) {
        setValue('subtitleId', subTitleList[0].id)
      }
      if (stickerList) {
        const id = stickerList.filter((el) => el.default === 1)[0]?.id
        setValue('stickerId', id ?? stickerList[0].id)
      }
      if (stickerLocationList) {
        setValue('stickerLocation', stickerLocationList[0]?.value)
      }
    }
  }
  useMemo(() => {
    if (voiceList) {
      setVoicePlaying(new Array(voiceList?.length).fill(false))
    }
    if (bgmList) {
      setBgmPlaying(new Array(bgmList?.length).fill(false))
    }
    if (aiPromptGroup) {
      setPromptStatus(new Array(aiPromptGroup.length).fill(false))
    }

    if (transitionList) {
      setVideoPlaying(new Array(transitionList.length).fill(false))
      setCopyTransitionList(transitionList)
    }
    if (outroList) {
      setCopyOutroList(outroList)
    }

    setVideoData()
    if (userInfo) {
      if (
        userInfo?.userType === UserType.체험 &&
        Number(userInfo?.usage?.usedCount) === 0
      ) {
        setGuideModal(isTime ? false : true)
      }
      setUsage(userInfo.usage)
    }
  }, [
    userInfo,
    aiPromptGroup,
    transitionList,
    bgmList,
    voiceList,
    stickerList,
    stickerLocationList,
  ])

  useEffect(() => {
    if (isTime === '1') {
      bgmRefetch()
      transitonRefetch()
      voiceRefetch()
      aiPromptGroupRefetch()
      thumbnailRefetch()
    }
  }, [isTime])

  useEffect(() => {
    bgmRefs.current.forEach((audioRef, index) => {
      if (audioRef && !bgmPlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [bgmPlaying])

  useEffect(() => {
    if (thumbnailList) {
      setThumbnailStatus(new Array(thumbnailList.length).fill(false))
    }
  }, [thumbnailList])

  useEffect(() => {
    if (isMobileTModal || isGuideModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isMobileTModal, isGuideModal])

  const handleUploadCheckboxChange = (
    productIndex: number,
    fileIndex: number
  ) => {
    const currentFiles = watch(`products.${productIndex}.files`, [])

    const currentSelectedFiles = watch(
      `products.${productIndex}.selectedFiles`,
      []
    )

    const fileToToggle = currentFiles[fileIndex]
    const isSelected = currentSelectedFiles.includes(fileToToggle)

    if (isSelected) {
      // 이미 선택된 파일을 선택 해제
      setValue(
        `products.${productIndex}.selectedFiles`,
        currentSelectedFiles.filter((file) => file !== fileToToggle)
      )
    } else {
      // 선택된 파일이 3개 이상이면 추가 선택을 막습니다.
      if (currentSelectedFiles.length >= 3) return

      // 선택되지 않은 파일을 선택
      setValue(`products.${productIndex}.selectedFiles`, [
        ...currentSelectedFiles,
        fileToToggle,
      ])
    }
  }
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      // 업로드한 파일을 products[index].files에 추가
      // @ts-ignore
      setValue(`products.${index}.files`, [
        ...watch(`products.${index}.files`),
        ...selectedFiles,
      ])

      // 현재 선택된 파일 배열 가져오기
      const prevSelected = watch(`products.${index}.selectedFiles`, [])
      const additionalSelectionCount = selectedFiles.length
      const currentSelectionCount = prevSelected ? prevSelected.length : 0

      // 선택된 파일 상태 업데이트 (최대 3개만 선택 가능)
      if (currentSelectionCount + additionalSelectionCount > 3) {
        const allowedSelectionCount = 3 - currentSelectionCount
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles.slice(0, allowedSelectionCount),
        ])
      } else {
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles,
        ])
      }
    }
  }
  const chartElByTab = () => {
    return (
      <>
        {userInfo?.userType === UserType.라이트 && (
          <>
            <LightPlan
              subscriptionType={
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
              }
              startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
              endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
              currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
              subscriptions={userPlanInfo?.subscriptions}
              monthStart={userPlanInfo?.subscriptions.monthStart ?? '--'}
              monthEnd={userPlanInfo?.subscriptions.monthEnd ?? '--'}
            />
          </>
        )}
        {(userInfo?.userType === UserType.프로 ||
          userInfo?.userType === UserType.프리미엄) &&
        userInfo?.subscriptions?.subscriptionType === 'annual' ? (
          <>
            <ProAndPremiumPlanAnnual
              userType={userInfo?.userType}
              subscriptionType={
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
              }
              startDate={userPlanInfo?.subscriptions?.monthStart ?? '--'}
              endDate={userPlanInfo?.subscriptions?.monthEnd ?? '--'}
              currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
              available={userPlanInfo?.usage?.available ?? false}
              couponBenefit={userInfo?.couponBenefit}
              isDone={userPlanInfo?.usage?.isDone}
              subscriptions={userPlanInfo?.subscriptions}
            />
          </>
        ) : (
          (userInfo?.userType === UserType.프로 ||
            userInfo?.userType === UserType.프리미엄) &&
          userInfo?.subscriptions?.subscriptionType === 'monthly' && (
            <>
              {' '}
              <ProAndPremiumPlanMonthly
                userType={userInfo?.userType}
                subscriptionType={
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
                }
                startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
                endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
                currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
                available={userPlanInfo?.usage?.available ?? false}
                isDone={userPlanInfo?.usage?.isDone}
                couponBenefit={userInfo?.couponBenefit}
                subscriptions={userPlanInfo?.subscriptions}
              />
            </>
          )
        )}
        {userInfo?.userType === UserType.USER && (
          <>
            <EmptyPlan
              subscriptionType={
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
              }
              startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
              endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
              currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
              setPlanPriceModal={setPlanPriceModal}
            />
          </>
        )}
        {userInfo?.userType === UserType.체험 && (
          <>
            <FreePlan
              userType={userInfo?.userType}
              subscriptionType={
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ?? ''
              }
              startDate={userPlanInfo?.subscriptions?.startDate ?? '--'}
              endDate={userPlanInfo?.subscriptions?.endDate ?? '--'}
              currentMonth={userPlanInfo?.subscriptions?.currentMonth ?? ''}
              totalLimit={userPlanInfo?.usage?.totalLimit ?? 0}
              usedCount={userPlanInfo?.usage?.usedCount ?? 0}
              available={userPlanInfo?.usage?.available ?? false}
              couponBenefit={userInfo?.couponBenefit}
              isDone={userPlanInfo?.usage?.isDone}
              subscriptions={userPlanInfo?.subscriptions}
            />
          </>
        )}
      </>
    )
  }

  const handleThumbnailError = () => {
    const updateError = { ...error }
    updateError.thumbnail = false
    setError(updateError)
  }

  const handlePriceModal = () => {
    /**
     * userInfo는 있고
     * 유저타입이 일반은 사람에게만 띄우기
     * 1.예전에 체험을 해본사람
     * 2. 무료체험 완료한사람
     * 3. 무료체험 신청전 일반유저
     */
    if (!userInfo) {
      navigate('/sign-up')
      return
    }
    if (!userPlanInfo && userInfo?.userType === UserType.체험) {
      return setPlanPriceModal((prev) => !prev)
    }
    if (userInfo?.userType === UserType.체험 && userInfo?.usage?.isDone) {
      return setPlanPriceModal((prev) => !prev)
    }
    if (userInfo?.userType === UserType.USER) {
      return setPlanPriceModal((prev) => !prev)
    }
  }

  const scrollToProducts = () => {
    if (productsRef.current) {
      productsRef.current.focus()
      productsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const selectErrorText = (
    mainTitle?: any,
    products?: any,
    thumbnailInfo?: any,
    subtitleId?: any
  ) => {
    const checkProducts = watch('products')
    const res = checkProducts.filter((el) => !el.link.trim())
    if (res.length === 3) {
      setErrorText(
        '최소 1개의 상품 링크 입력을 하셔야 영상 생성 요청이 가능합니다.'
      )
      scrollToProducts()
      return
    }

    if (products) {
      // 에러가 1~3개 까지 발생가능
      // 각각 링크와 상품명에 대해서 발생 가능
      // text => 상품 1의 상품명을 입력해주세요
      for (const product of products) {
        if (product) {
          const { link, productName } = product
          const linkIndex = link?.ref?.name.replace(
            /products|\blink\b|\.|/g,
            ''
          )
          const nameIndex = productName?.ref?.name.replace(
            /products|\bproductName\b|\.|/g,
            ''
          )
          if (link?.type === 'validate' || link?.type === 'required') {
            setErrorText(`상품 ${Number(linkIndex) + 1}의 링크를 확인해주세요.`)
            scrollToProducts()
            return
          } else if (productName?.message === 'required') {
            setErrorText(
              `상품 ${Number(nameIndex) + 1}의 상품명을 입력해주세요`
            )

            scrollToProducts()
            return
          } else if (productName?.type === 'maxLength') {
            setErrorText(
              `상품 ${Number(nameIndex) + 1}의 상품명 글자 수가 초과되었어요`
            )

            scrollToProducts()
            return
          }
        }
      }
    }
    if (mainTitle) {
      const { type } = mainTitle
      if (type === 'required') {
        setErrorText('유튜브 영상용 제목을 입력해주세요.')
      } else {
        setErrorText('유튜브 영상용 제목 글자 수가 초과되었어요')
      }
      if (mainTitleInputRef.current) {
        mainTitleInputRef.current.focus()
        mainTitleInputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      return
    }
    if (thumbnailInfo) {
      setErrorText('썸네일 스타일과 썸네일 내용을 입력해주세요')
      if (thumbNailDivRef.current) {
        thumbNailDivRef.current.focus()
        thumbNailDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
      return
    }

    if (subtitleId) {
      setErrorText('나레이션 자막 삽입 여부를 선택해주세요')
      if (subtitleDivRef.current) {
        subtitleDivRef.current.focus()
        subtitleDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      return
    }
    setErrorText('errors')
  }

  const findError = (mainTitle: any, products: any, thumbnail: any) => {
    const updateError = { ...error }
    //1. mainTtitle
    if (mainTitle) {
      updateError.mainTitle = true
    } else {
      updateError.mainTitle = false
    }
    if (thumbnail) {
      updateError.thumbnail = true
    } else {
      updateError.thumbnail = false
    }
    if (products) {
      for (const product of products) {
        if (product) {
          const { link, productName } = product

          if (link) {
            const linkIndex = link?.ref?.name.replace(
              /products|\blink\b|\.|/g,
              ''
            )
            updateError.products[linkIndex].link = true
          }
          if (productName) {
            const nameIndex = productName?.ref?.name.replace(
              /products|\bproductName\b|\.|/g,
              ''
            )
            updateError.products[nameIndex].product = true
          }
        }
      }
    } else {
      updateError.products = [
        { link: false, product: false },
        { link: false, product: false },
        { link: false, product: false },
      ]
    }

    setError(updateError)
  }

  const getGridItemStyle = (selected: boolean): CSSProperties => ({
    width: '130px',
    height: '130px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #D9DEE8',
    overflow: 'hidden', // 이미지가 부모의 경계를 넘어가지 않도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    opacity: selected ? 1 : 0.3, // 선택되지 않은 경우 투명도 조절
  })

  // @ts-ignore
  const styles: { [key: string]: CSSProperties } = {
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridContainerMobile: {
      gridTemplateColumns: 'repeat(3, 1fr)', // 각 아이템의 너비를 유지하면서 자동으로 간격 조정
      justifyItems: 'center',
    },
    gridItemMobile: {
      width: '93px',
      height: '93px',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain', // 비율을 유지하며 부모를 꽉 채우도록 설정
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '8px',
      width: '16px',
      height: '16px',
    },
    label: {
      fontSize: '16px',
      color: '#333',
    },
  }

  return (
    <>
      <div className="md:w-[816px] w-[375px] my-0 mx-auto flex flex-col items-center gap-[16px]">
        {/* 회원 / 비회원 타이틀 다르게 */}
        {userInfo ? <MemberTitle /> : <NoneMemberTitle />}
        {/* 페이백 배너 렌더링 */}
        {renderPaybackVideo(userInfo)}
        {/* 무료체험 배너 */}
        {isFreePlanOrUser()}
        {/* 현황 데시보드 */}
        {userInfo && (
          <div className=" rounded-[20px] bg-[#F6F8FB] md:px-10 md:py-8 p-[10px] flex flex-col md:flex-row md:w-full w-[343px] gap-3 md:justify-between relative">
            {chartElByTab()}
          </div>
        )}
        {/* 유튜브 / 업그레이드 / 사용소진 / 미션쿠폰 등 */}
        <div className="flex flex-col gap-5 items-center md:w-[776px] w-[343px] ">
          {' '}
          {userInfo?.couponBenefit && (
            <div className="md:w-full w-[343px] flex justify-center">
              <button
                className="bg-main text-white w-full h-[42px] rounded-[40px]"
                style={{
                  background: 'linear-gradient(to right, #6095FF, #D512E7)',
                }}
              >
                <span className="font-[800] font-[Tenada]">
                  {userInfo.couponBenefit.couponDescription}
                </span>
              </button>
            </div>
          )}{' '}
          <div className="w-full flex flex-col gap-5 md:flex-row md:gap-[14px] md:justify-center items-center ">
            {userInfo?.affiliate?.plan?.length > 0 && (
              <PlanPromotionBanner
                daysLeft={userInfo?.affiliate.plan[0].daysLeft}
                hoursLeft={userInfo?.affiliate.plan[0].hoursLeft}
                affiliateImage={userInfo?.affiliate.plan[0].affiliateImage}
                affiliateDescription={
                  userInfo?.affiliate.plan[0].affiliateDescription
                }
                description={userInfo?.affiliate.plan[0].description}
              />
            )}
            {showYoutubeAuth(
              userInfo,
              'flex justify-center items-center w-[343px] h-[46px] bg-error text-white font-bold text-[14px] rounded-[40px]'
            )}
            <PromotionCircle
              userInfo={userInfo}
              planId={userInfo?.subscriptionType?.planId}
              classNameProps="w-full h-[34px]  rounded-full text-white font-bold"
            />
          </div>
          {/* 횟수 제한 표시 dic */}
          {userInfo &&
            usage &&
            !usage?.available &&
            userInfo?.userType !== UserType.체험 && (
              <div
                className="bg-[#F6F8FB] md:w-[816px]  md:h-[46px] flex md:flex-row 
               w-[343px] h-[58px] flex-col items-center justify-center rounded-[40px] md:gap-[18px] "
              >
                <p className="font-bold md:text-[18px] text-[14px]">
                  기한 내 사용 가능 횟수를 모두 소진하셨어요!
                </p>
                <p className="font-bold md:text-[18px] text-[14px]">
                  <span className="text-main">요청 {usage.usedCount}개</span> /{' '}
                  {usage?.totalLimit}개
                </p>
              </div>
            )}
        </div>
        <form
          onClick={handlePriceModal}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              console.log('Enter key detected but prevented submission')
            }
          }}
          onSubmit={handleSubmit(onSubmit, (err) => {
            if (!userInfo) {
              return navigate('/sign-up')
            }
            if (window.gtag) {
              // Event snippet for 영상생성요청클릭 conversion page

              window.gtag('event', 'conversion', {
                send_to: 'AW-677452397/OM8ECK_B3IwaEO20hMMC',
                value: 1.0,
                currency: 'KRW',
              })
            } else {
              console.error('gtag is not defined')
            }

            const { mainTitle, products, thumbnailInfo, subtitleId } = err
            console.log(err)

            findError(mainTitle, products, thumbnailInfo)
            selectErrorText(mainTitle, products, thumbnailInfo, subtitleId)
            setEssentialCheck(true)
          })}
          className={`flex flex-col gap-[20px]  ${
            usage &&
            userInfo?.userType !== UserType.체험 &&
            !usage?.available &&
            'opacity-[0.2]'
          }`}
        >
          <ShadowBox className="md:w-[816px] w-[375px]">
            <div className="flex flex-col items-center gap-[4px] md:gap-[16px] w-[375px] md:w-[736px]">
              <div className="flex flex-col items-center mt-[40px] md:mt-0 w-[343px] md:w-full">
                <h3 className="font-bold text-[#2E343D] text-[24px] ">
                  상품 링크 입력
                </h3>
                <p className="text-main text-[14px]">
                  1~3개의 상품 정보와 리뷰로 영상을 만들어보세요!
                </p>

                <p className="text-[#464DEE] text-[14px] text-center">
                  🧐 대표 영상 소스가 있는 알리익스프레스 상품 영상 퀄리티가 더
                  좋습니다!
                </p>

                <p className="text-[14px] text-center text-error">
                  🚨 상품페이지 링크만 가능해요 (목록 페이지는 안돼요!)
                </p>
              </div>

              <div className="flex flex-col gap-[16px] mt-6">
                <div className="flex flex-col items-center w-[375px] md:w-[736px] gap-6 ">
                  {fields.map((item, index) => (
                    <>
                      <div className="flex flex-col items-center w-[375px] md:w-full gap-1">
                        <div
                          key={index}
                          className="w-[343px] md:w-full flex justify-between"
                        >
                          <p className="text-[18px] font-bold">
                            상품 {index + 1}
                          </p>
                        </div>
                        <div className="relative flex flex-col">
                          <Controller
                            key={`link_${index}`}
                            render={({ field }) =>
                              isMobile ? (
                                <>
                                  {' '}
                                  <TextArea
                                    inputRef={index === 0 ? productsRef : null}
                                    disabled={
                                      usage &&
                                      !usage?.available &&
                                      userInfo?.userType !== UserType.체험
                                    }
                                    error={error.products[index].link}
                                    {...field}
                                    placeholder="쿠팡파트너스 또는 알리어필리에이트 링크 Ctrl+c, Ctrl+v 로 붙여넣기"
                                    style={{
                                      borderColor:
                                        errors.products &&
                                        errors.products[index] &&
                                        errors.products[index]?.link
                                          ?.message === 'invalid' &&
                                        'red',
                                    }}
                                  />
                                </>
                              ) : (
                                <Input
                                  inputRef={index === 0 ? productsRef : null}
                                  disabled={
                                    usage &&
                                    !usage?.available &&
                                    userInfo?.userType !== UserType.체험
                                  }
                                  {...field}
                                  placeholder="쿠팡파트너스 또는 알리어필리에이트 링크 Ctrl+c, Ctrl+v 로 붙여넣기"
                                  error={error.products[index].link}
                                />
                              )
                            }
                            name={`products.${index}.link`}
                            control={control}
                            rules={{
                              required:
                                (index === 0 ||
                                  watch(`products.${index}.productName`)) !== ''
                                  ? 'required'
                                  : false,
                              validate:
                                index === 0 ||
                                watch(`products.${index}.productName`) !== ''
                                  ? (value) => {
                                      const updateError = { ...error }
                                      updateError.products[index].link = false
                                      setError(updateError)
                                      return (
                                        URL_PATTERN.test(value) || `invalid`
                                      )
                                    }
                                  : undefined,
                            }}
                          />
                          {errors.products &&
                            errors.products[index]?.link &&
                            errors.products[index]?.link?.message ===
                              'invalid' && (
                              <span className="text-error">
                                유효하지 않은 링크입니다!
                              </span>
                            )}
                          {index === 0 && (
                            <>
                              <img
                                src="assets/img/icon_tooltip.png"
                                alt="툴팁"
                                className="absolute right-5 top-[24px]  w-[24px] h-[24px]"
                                onMouseOver={() => {
                                  setTooltipStatus(true)
                                }}
                              />
                              {tooltipStatus && (
                                <div
                                  className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[360px] h-auto absolute border-[3px] border-main  rounded-[40px] bottom-[50px] right-0"
                                  onMouseLeave={() => {
                                    setTooltipStatus(false)
                                  }}
                                >
                                  <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px]">
                                    <span className="font-bold">
                                      쿠팡파트너스? 알리어필리에이트?
                                    </span>
                                    <br />위 서비스들의 계정을 생성하셔야,
                                    제휴마케팅 리워드를 받을 수 있는 상품 링크를
                                    복사할 수 있어요!
                                    <br />
                                    아직 만들지 않으셨다면,
                                    <br />
                                    패스트컷의 가이드를 보고 쉽게 만들어보세요!
                                  </p>
                                  <p className="flex gap-2 md:text-base text-[10px]">
                                    <img
                                      src="assets/img/coupang_icon.png"
                                      alt="coupang"
                                      width={24}
                                      height={24}
                                      className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                                    />
                                    <a
                                      href="https://blog.fastseller.shop/coupangpartnersguide/"
                                      target="_blank"
                                      rel="noreferrer"
                                      className="md:text-base text-[10px]"
                                    >
                                      {' '}
                                      {`쿠팡 파트너스 가이드 >`}
                                    </a>
                                  </p>
                                  <p className="flex gap-2 md:text-base text-[10px]">
                                    <img
                                      src="assets/img/ali_icon.png"
                                      alt="ali"
                                      className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                                    />
                                    <a
                                      href="https://blog.fastseller.shop/aliexpressaffiliatteguide/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >{`알리익스프레스 어필리에이트 가이드 >`}</a>
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="product_title">
                          <Controller
                            key={`productName_${index}`}
                            render={({ field }) => (
                              <>
                                {isMobile ? (
                                  <>
                                    {' '}
                                    <TextArea
                                      error={error.products[index].product}
                                      disabled={
                                        usage &&
                                        !usage?.available &&
                                        userInfo?.userType !== UserType.체험
                                      }
                                      {...field}
                                      placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                                      maxLength={100}
                                    />
                                  </>
                                ) : (
                                  <Input
                                    error={error.products[index].product}
                                    disabled={
                                      usage &&
                                      !usage?.available &&
                                      userInfo?.userType !== UserType.체험
                                    }
                                    {...field}
                                    placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (50자 이하)"
                                    maxLength={100}
                                  />
                                )}

                                <div
                                  className={`flex gap-[4px] mt-1 md:flex-row flex-col  ${
                                    (field.value.length > 50 ||
                                      !PRODUCT_NAME_PATTERN.test(
                                        field.value
                                      )) &&
                                    'text-error'
                                  }`}
                                >
                                  <span>{field.value.length}/50</span>
                                  <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                                  {errors.products &&
                                    errors.products[index]?.productName && (
                                      <span className="text-error ml-[10px]">
                                        {errors.products[index]?.productName
                                          ?.type === 'required' &&
                                          '상품명을 입력해주세요.'}
                                      </span>
                                    )}
                                </div>
                              </>
                            )}
                            name={`products.${index}.productName`}
                            control={control}
                            rules={{
                              required:
                                (index === 0 ||
                                  watch(`products.${index}.productName`)) !== ''
                                  ? 'required'
                                  : false,
                              validate:
                                index === 0 ||
                                watch(`products.${index}.productName`) !== ''
                                  ? (value) => {
                                      const updateError = { ...error }
                                      updateError.products[index].product =
                                        false
                                      setError(updateError)
                                      return (
                                        PRODUCT_NAME_PATTERN.test(value) ||
                                        '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                                      )
                                    }
                                  : undefined,
                              maxLength: 50,
                            }}
                          />
                        </div>
                        <div className="flex justify-between md:w-full w-[343px]  bg-[#EEF1F7] items-center px-5 py-[13px] rounded-[10px]">
                          <p className="font-bold text-[14px]">
                            [선택] 이미지 업로드{' '}
                            {
                              watch(`products.${index}.files`, []).filter(
                                (image) => image
                              ).length
                            }
                            /3
                            <span className="font-[400]">
                              {isMobile ? (
                                <> (최대 3장)</>
                              ) : (
                                <> (최대 3장 선택 가능)</>
                              )}
                            </span>
                          </p>
                          <button
                            className="flex items-center justify-center gap-[8px] w-[65px] h-[44px] md:text-base text-[14px] "
                            type="button"
                            onClick={() => {
                              handleImageDivOpen(index)
                            }}
                          >
                            <span>
                              {isImageDivOpen[index] ? '접기' : '펼치기'}
                            </span>
                            <img
                              src="assets/img/icon_up_arrow.png"
                              alt="arrow"
                              className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
                                isImageDivOpen[index] ? '' : 'rotate-180'
                              }`}
                            />
                          </button>
                        </div>
                        <CSSTransition
                          in={isImageDivOpen[index]}
                          timeout={200}
                          classNames="content"
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              background: '#F6F8FB',
                              borderRadius: '10px',
                              padding: '20px',
                              maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                              overflowY: 'auto', // 스크롤 가능하게 설정
                              scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                              scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                            }}
                          >
                            {index === 0 && (
                              <>
                                <p className="text-[14px] text-error mb-1">
                                  상품 페이지 대표 이미지 외에 따로 이미지
                                  추가를 원하시는 경우에만 업로드 해주세요.
                                </p>
                                <p className="text-[14px] text-error mb-1">
                                  상품 페이지 영상이 없는 경우에만 적용되며,
                                  상품 영상 생성시 업로드 이미지를 앞쪽에
                                  배치합니다.
                                </p>
                              </>
                            )}
                            <div className="flex items-center space-x-2 mb-3">
                              <span className="text-[#8F929B] text-[16px] text-sm">
                                최대 5MB | jpg, jpeg, png
                              </span>
                              <button
                                onClick={(event) => {
                                  event.preventDefault() // 기본 form 제출 동작을 방지
                                  document
                                    .getElementById(`file-input-${index}`)
                                    ?.click() // 파일 선택 창 열기
                                }}
                                className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                              >
                                <img
                                  src="assets/img/icon_image.png"
                                  alt=""
                                  width={17}
                                />
                                <span>파일 업로드</span>
                              </button>
                              <input
                                id={`file-input-${index}`}
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                multiple
                                className="hidden"
                                onChange={(e) => handleFileChange(e, index)}
                              />
                            </div>

                            <div
                              style={{
                                ...styles.gridContainer,
                                ...(window.innerWidth <= 768
                                  ? styles.gridContainerMobile
                                  : {}),
                              }}
                            >
                              {watch(`products.${index}.files`, []).map(
                                (file, fileIndex) => {
                                  const selectedFiles = watch(
                                    `products.${index}.selectedFiles`,
                                    []
                                  )
                                  console.log(
                                    'selectedFiles:::::',
                                    selectedFiles
                                  )
                                  return (
                                    <div
                                      style={{ marginBottom: '20px' }}
                                      key={fileIndex}
                                    >
                                      <div
                                        style={{
                                          ...getGridItemStyle(
                                            watch(
                                              `products.${index}.selectedFiles`,
                                              []
                                            ).includes(file)
                                          ),
                                          ...(window.innerWidth <= 768
                                            ? styles.gridItemMobile
                                            : {}),
                                        }}
                                      >
                                        <img
                                          src={URL.createObjectURL(file)}
                                          key={`Image ${fileIndex + 1}`}
                                          alt=""
                                          style={styles.image}
                                        />
                                      </div>
                                      <div style={styles.checkboxContainer}>
                                        <input
                                          type="checkbox"
                                          checked={watch(
                                            `products.${index}.selectedFiles`,
                                            []
                                          ).includes(file)}
                                          onChange={() =>
                                            handleUploadCheckboxChange(
                                              index,
                                              fileIndex
                                            )
                                          }
                                          style={styles.checkbox}
                                        />
                                        <span style={styles.label}>
                                          {String(fileIndex + 1).padStart(
                                            2,
                                            '0'
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </CSSTransition>
                      </div>
                    </>
                  ))}
                </div>
                <div className="w-full flex justify-center">
                  {fields.length < 3 && (
                    <button
                      type="button"
                      onClick={() => {
                        if (fields.length < 3) {
                          append({
                            link: '',
                            productName: '',
                            files: [],
                            selectedFiles: [],
                          })
                        }
                      }}
                    >
                      <img
                        src="assets/img/add.png"
                        height={32}
                        width={32}
                        alt="add"
                      />
                    </button>
                  )}
                </div>
                <div
                  className="w-full flex flex-col md:p-0 px-5 pb-5 gap-2"
                  ref={scrollToRef}
                >
                  <Controller
                    key={`whitShort`}
                    render={({ field }) => (
                      <>
                        <label className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="w-[18px] h-[18px]"
                            checked={watch('withShort')}
                            onChange={() => {
                              const status = watch('withShort')
                              setValue('withShort', !status)
                              if (status) {
                                setValue('shortInfo.subtitleFlag', 0)
                              }
                            }}
                          />
                          <span className="font-bold text-[18px]">
                            숏폼도 함께 생성하기
                          </span>
                          <p className=" relative">
                            {' '}
                            <img
                              src="assets/img/icon_tooltip.png"
                              alt="툴팁"
                              className="  w-[20px] h-[20px]"
                              onMouseOver={() => {
                                setWithShortsStatus((prev) => !prev)
                              }}
                              onMouseLeave={() => {
                                setWithShortsStatus((prev) => !prev)
                              }}
                            />
                            {withShortsStatus && (
                              <WithShortsTooltip
                                setWithShortsStatus={setWithShortsStatus}
                              />
                            )}
                          </p>
                          <img
                            src="assets/img/cash.png"
                            alt="툴팁"
                            className="  w-[17px] h-[20px]"
                            onMouseOver={() => {}}
                          />
                          <span className="font-bold text-main text-[14px]">
                            별도 캐시 필요
                          </span>
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="w-[18px] h-[18px]"
                            checked={watch('shortInfo.subtitleFlag') === 1}
                            onChange={() => {
                              if (watch('withShort')) {
                                setValue(
                                  'shortInfo.subtitleFlag',
                                  watch('shortInfo.subtitleFlag') === 1 ? 0 : 1
                                )
                              }
                            }}
                          />
                          <span className="font-bold text-[18px]">
                            숏폼 자막 넣기
                          </span>
                          <span
                            className="text-[14px] font-bold w-[38px] h-[21px] text-[white] flex justify-center items-center rounded-[4px]"
                            style={{
                              background: `linear-gradient(to right,#9A60FF,#2580FF)`,
                            }}
                          >
                            βeta
                          </span>

                          <img
                            src="assets/img/gray_cash.png"
                            alt="툴팁"
                            className="  w-[17px] h-[20px]"
                          />
                          <span className="font-bold text-[#8F929B] text-[14px]">
                            {isMobile
                              ? '베타 기간 무료'
                              : '베타 서비스 기간 동안 무료'}
                          </span>
                        </label>
                        <ul className=" list-disc text-[#5E6068] text-[14px] ml-5">
                          <li>숏폼도 함께 생성시 캐시가 소진됩니다.</li>
                          <li>
                            상품별로 각 1개씩 자동 생성됩니다. (상품이 3개면
                            숏폼 3개 생성)
                          </li>
                          <li>
                            생성 완료시 사용현황 {'>'} 숏폼 목록에서 다운로드
                            받으실 수 있으며, 자동 업로드 되지 않습니다.
                          </li>
                        </ul>
                      </>
                    )}
                    name={`withShort`}
                    control={control}
                    rules={{
                      required: false,
                    }}
                  />
                </div>
              </div>
            </div>
          </ShadowBox>
          {isSample === '1' && (
            <div className=" sample md:w-[816px] my-[40px] flex flex-col gap-[24px] bg-[#F6F8FB] p-[20px] md:p-[40px] md:rounded-[40px]">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p className="h-[15px]"></p>
                  <p className="text-[#2E343D] font-bold leading-[28.8px] text-left text-[24px] h-[120px] md:h-[60px]">
                    쿠팡파트너스 / 알리어필리에이트 링크가 아직 없으신가요?
                    <br /> 다음 링크를 사용해서 패스트컷을 경험해보세요!
                  </p>
                </div>

                <div className="flex flex-col md:flex-row">
                  <p className="flex w-[40px] h-[40px] justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        saveLocalStorage('guide_sample', '0')
                        setSample('0')
                      }}
                    >
                      <img
                        src="assets/img/Icon_x.png"
                        alt="icon_x"
                        height={40}
                        width={40}
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-[4px]">
                {products?.map((el) => {
                  return (
                    <>
                      <div className="flex gap-[4px] w-full md:w-[50%]">
                        <div className="w-[120px] h-[120px]">
                          <img
                            src={el.thumbnail}
                            alt="sample"
                            className="border rounded-[10px] w-[120px] h-[120px]"
                            loading={'lazy'}
                          />
                        </div>
                        <div className="w-[211px] md:w-[218.5px] flex flex-col justify-between break-words gap-[6px]">
                          <a
                            href={el.url}
                            target="_blank"
                            className="font-bold text-blue-500 underline"
                            rel="noreferrer"
                          >
                            {el.url}
                          </a>
                          <p className="font-bold leading-[20.88px]">
                            {el.title}
                          </p>
                          <p>{el.price}</p>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}

          <ShadowBox className="py-[40px]  md:w-[816px]">
            <div className="flex flex-col gap-[16px]  w-full ">
              <div className="flex flex-col items-center ">
                <h3 className="font-bold text-[#2E343D] text-[24px] ">
                  유튜브 영상용 제목 입력
                </h3>
                <p className="text-main text-[14px]">
                  매력적인 내용을 정해주세요!
                </p>
              </div>
              <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                <div className="relative">
                  <Controller
                    key="mainTitle"
                    render={({ field }) => (
                      <>
                        {isMobile ? (
                          <>
                            {' '}
                            <TextArea
                              inputRef={mainTitleInputRef}
                              error={error.mainTitle}
                              disabled={
                                usage &&
                                !usage?.available &&
                                userInfo?.userType !== UserType.체험
                              }
                              {...field}
                              placeholder="유튜브 영상용 제목 입력 (90자 이하)"
                              maxLength={90}
                            />
                          </>
                        ) : (
                          <Input
                            inputRef={mainTitleInputRef}
                            error={error.mainTitle}
                            disabled={
                              usage &&
                              !usage?.available &&
                              userInfo?.userType !== UserType.체험
                            }
                            {...field}
                            placeholder="유튜브 영상용 제목 입력 (90자 이하)"
                            maxLength={90}
                          />
                        )}
                        <div
                          className={`flex gap-[4px] mt-[8px] ${
                            (field.value.length > 90 ||
                              !PRODUCT_NAME_PATTERN.test(field.value)) &&
                            'text-error'
                          }`}
                        >
                          <span>{field.value.length}/90</span>
                          <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                        </div>
                      </>
                    )}
                    name={'mainTitle'}
                    rules={{
                      required: {
                        value: true,
                        message: '유튜브 영상 제목은 필수입니다!',
                      },
                      maxLength: {
                        value: 90,
                        message: '유튜브 영상 제목의 길이가 90자를 넘습니다.',
                      },
                      validate: (value) => {
                        const updateError = { ...error }
                        updateError.mainTitle = false
                        setError(updateError)
                        return (
                          PRODUCT_NAME_PATTERN.test(value) ||
                          '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                        )
                      },
                    }}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </ShadowBox>

          <ShadowBox className="py-[40px] px-[16px] md:w-[816px] ">
            <div className="flex flex-col gap-[40px] md:gap-0 w-[343px] md:w-[736px]">
              <div className="flex flex-col items-center  mt-[16px] md:mb-[40px] ">
                <p className="font-bold text-[#2E343D] text-[24px] ">
                  효과 선택
                </p>
                <p className="text-[#FFFFFF] bg-main text-[10px] font-bold w-[159px] text-center rounded-[4px] h-[18px] flex items-center justify-center">
                  <span>프로 / 프리미엄 플랜을 위한 기능</span>
                </p>
                <p className="text-main text-[14px]">
                  원하는 분위기의 영상 생성을 위해 각 효과를 선택해주세요!
                </p>
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames="content"
                unmountOnExit
              >
                <div className="" ref={thumbNailDivRef}>
                  {isMobile ? (
                    <>
                      <div className="thumbnail_mobile flex flex-col items-center mb-[40px]">
                        <p className="font-bold text-[18px] mb-[16px] text-left w-full">
                          썸네일 내용 입력 + 썸네일 스타일
                        </p>
                        <div className="flex justify-start w-full gap-5">
                          {selectedThumbnail ? (
                            <div className="flex gap-5">
                              <img
                                src={selectedThumbnail?.path}
                                alt="thumbnail"
                                className="w-[138px] h-[78px]"
                              />{' '}
                              <div className="flex h-full flex-col justify-between">
                                <span className="flex gap-[2px] ">
                                  {selectedThumbnail?.name}
                                  <PlanTag
                                    planName={translatePlanName(
                                      selectedThumbnail?.planName
                                    )}
                                  />
                                </span>
                                <button
                                  className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px] font-bold w-[53px]"
                                  type="button"
                                  onClick={() => {
                                    setMobileTModal(true)
                                  }}
                                >
                                  선택 {'>'}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="w-[138px] h-[78px] bg-[#EEF1F7]"></div>
                              <div className="flex flex-col gap-5">
                                <p className="text-error">
                                  썸네일을 선택해주세요!
                                </p>
                                <button
                                  className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px] font-bold w-[53px]"
                                  type="button"
                                  onClick={() => {
                                    setMobileTModal(true)
                                  }}
                                >
                                  선택 {'>'}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={classNames('thumbnail_pc', {
                          'mb-4': watch('thumbnailId') !== 0,
                          'mb-10': watch('thumbnailId') === 0,
                        })}
                        ref={thumbNailDivRef}
                      >
                        <p className="font-bold text-[18px] mb-[16px]">
                          썸네일 내용 입력 + 썸네일 스타일
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-5  gap-y-[16px]">
                          {thumbnailList?.map((thumbnail, index) => {
                            return (
                              <>
                                <div
                                  className={`flex gap-[24px] md:flex-col md:gap-2 relative ${thumbnail?.name} `}
                                  key={index}
                                  ref={(el) =>
                                    (thumbnailRefs.current[index] = el)
                                  }
                                >
                                  <ThumbnailImgComponent
                                    thumbnail={thumbnail}
                                    index={thumbnailStatus[index]}
                                  />
                                  {/* 
                                  편집하기 버튼 주석처리
                                  {thumbnailStatus[index] && (
                                    <button
                                      className=" absolute left-7 top-7 md:bottom-[50%] md:left-[20%] bg-main z-50 w-[79px] h-[24px] rounded-[20px] flex justify-center items-center"
                                      type="button"
                                      onClick={() => {
                                        setThumbnailType(
                                          thumbnailOptions[
                                            `thumbnail_${thumbnail.name}`
                                          ]
                                        )
                                        setEditThumbnail((prev) => !prev)
                                      }}
                                    >
                                      <span className="font-bold text-[#FFFFFF] text-[12px]">
                                        편집하기
                                      </span>
                                    </button>
                                  )} */}
                                  <div
                                    className={`relative flex gap-[8px] items-center ${thumbnail?.name}`}
                                  >
                                    <Controller
                                      render={({ field }) => (
                                        <CircularCheckbox
                                          {...field}
                                          id={index}
                                          checked={
                                            watch('thumbnailId') ===
                                            thumbnail.id
                                          }
                                          disabled={
                                            !userInfo || !thumbnail?.usable
                                          }
                                          onChange={() => {
                                            setValue(
                                              'thumbnailId',
                                              thumbnail.id
                                            )
                                            handleThumbnail(index)
                                            setValue('thumbnailInfo', {
                                              channelName: '',
                                              title1: '',
                                              title2: '',
                                              title3: '',
                                              thumbnailFile: [],
                                            })
                                            setThumbnailType(
                                              thumbnailOptions[
                                                `thumbnail_${thumbnail.name}`
                                              ]
                                            )
                                          }}
                                          label={thumbnail.name}
                                        />
                                      )}
                                      name="thumbnailId"
                                      control={control}
                                    />

                                    <PlanTag
                                      planName={translatePlanName(
                                        thumbnail.planName
                                      )}
                                      className="absolute left-[35%] top-0"
                                    />
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                      {watch('thumbnailId') !== 0 && (
                        <ThumbnailDiv
                          key={watch('thumbnailId')} // key 값 설정
                          thumbnailId={watch('thumbnailId')}
                          editThumbnail={editThumbnail}
                          userInfo={userInfo}
                          thumbnailType={thumbnailType}
                          setValue={setValue}
                          watch={watch}
                          control={control}
                          error={error.thumbnail}
                          handleThumbnailError={handleThumbnailError}
                        />
                      )}
                    </>
                  )}
                  <div className="subtitle mb-[40px]" ref={subtitleDivRef}>
                    <p className="text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">나레이션 자막</span>
                    </p>

                    <div className="grid grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {subTitleList?.map((subtitle, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex ">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('subtitleId') === subtitle.id
                                      }
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() =>
                                        setValue('subtitleId', subtitle.id)
                                      }
                                      label={subtitle.name}
                                    />
                                  )}
                                  rules={{
                                    validate: (value) => {
                                      return Number(watch('subtitleId')) === 0
                                        ? 'Subtitle ID must not be 0'
                                        : true
                                    },
                                  }}
                                  name="subtitleId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      subtitle.planName
                                    )}
                                    className=""
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  {/* 컨셉 / 나레이션 / 톤 / 목소리 통합  */}
                  <AiScriptDisplay
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    translatePlanName={translatePlanName}
                    userInfo={userInfo}
                    promptGroup={aiPromptGroup}
                    setPromptStatus={setPromptStatus}
                    promptStatus={promptStatus}
                    voiceList={voiceList}
                    setVoicePlaying={setVoicePlaying}
                    voicePlaying={voicePlaying}
                    voiceTags={voiceTags}
                    selectedVoiceId={watch('voiceId')}
                    setToneTooltip={setToneTooltip}
                    toneTooltip={toneTooltip}
                  />

                  {/* BGM  선택   */}
                  <BgmDisplay
                    bgmList={bgmList ?? []}
                    setBgmPlaying={setBgmPlaying}
                    bgmPlaying={bgmPlaying}
                    watch={watch}
                    setValue={setValue}
                    translatePlanName={translatePlanName}
                    bgmRefs={bgmRefs}
                    handleBgmPlayButtonClick={handleBgmPlayButtonClick}
                    userInfo={userInfo}
                    control={control}
                  />

                  {/* 트랜지션 선택  */}
                  <Transition
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    translatePlanName={translatePlanName}
                    setSelectedTransitionIndex={setSelectedTransitionIndex}
                    handleMouseLeave={handleMouseLeave}
                    handleVideoEnded={handleVideoEnded}
                    copyTransitionList={copyTransitionList}
                    handlePlayButtonClick={handlePlayButtonClick}
                    userInfo={userInfo}
                    videoPlaying={videoPlaying}
                    videoRefs={videoRefs}
                    videoRef={videoRef}
                    selectedTransitionIndex={selectedTransitionIndex}
                    setTransitionModal={setTransitionModal}
                    handleSelectedTransition={handleSelectedTransition}
                    transitionDivRef={transitionDivRef}
                  />

                  {/* 상품가격정보 넣기/빼기   */}
                  <PriceDisplay
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    userInfo={userInfo}
                  />
                  {/* 영상 우상단 안내 이미지 */}
                  <IncludeAddDisplay
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    userInfo={userInfo}
                  />

                  {/* 영상 시작 인트로 넣기/빼기*/}
                  <IntroDisplay
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    userInfo={userInfo}
                  />

                  <StickerDisplay
                    userInfo={userInfo}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    translatePlanName={translatePlanName}
                    stickerLocationList={stickerLocationList}
                    stickerList={stickerList}
                  />
                  {/* 좋구알 list  */}
                  <OutroDisplay
                    userInfo={userInfo}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    translatePlanName={translatePlanName}
                    copyOutroList={copyOutroList}
                    setCopyOutroList={setCopyOutroList}
                  />
                </div>
              </CSSTransition>
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center justify-center gap-[8px] w-full py-[12.5px] bg-[#EEF1F7] rounded-[40px]"
                  type="button"
                  onClick={toggleContent}
                >
                  <span>{isOpen ? '접기' : '펼치기'}</span>
                  <img
                    src="assets/img/icon_up_arrow.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px]  transition-transform duration-1000 ${
                      isOpen ? '' : 'rotate-180'
                    }`}
                  />
                </button>
              </div>
            </div>
          </ShadowBox>
          <div className="text-[16px] text-center">
            패스트컷은 사용자가 제공하는 URL에 있는 텍스트/이미지/영상을 토대로
            영상을 만들고 있으며,
            <br /> 링크 내의 텍스트나 이미지, 영상 등이 영상에 사용될 수
            있습니다.
            <br /> 이에 대한 지적재산권 침해 여부는 사용자가 확인하여야 하며,
            이에 관하여 패스트컷은 책임이 없습니다.
          </div>
          <div>
            <div className="flex flex-col justify-center items-center gap-[20px]">
              <button
                className={classNames(
                  'h-[60px] flex items-center justify-center bg-main gap-[4px] w-[343px] md:w-full border-none rounded-[30px]  text-[#FFFFFF] font-bold',
                  {
                    'opacity-50 hover':
                      !userInfo ||
                      (userInfo?.userInfo && usage && !usage.available) ||
                      !usage ||
                      isPending,
                  }
                )}
                disabled={
                  !userInfo || userInfo
                    ? (userInfo?.userInfo && usage && !usage.available) ||
                      !usage ||
                      isPending
                    : false
                }
              >
                <span>영상 생성 요청</span>
              </button>
            </div>
          </div>
        </form>

        <div
          className={classNames('', {
            'flex flex-col w-full bg-[#F6F8FB] px-8 py-10 rounded-[40px] gap-8 ':
              !isMobile,
            'flex flex-col w-full bg-[#F6F8FB] px-8 py-10  gap-7': isMobile,
          })}
        >
          <p className="font-bold text-[24px]">
            🎯 궁금함이 해소 돼요! 자주 묻는 질문
          </p>
          <div>
            <p className="font-bold text-[20px] ">영상 생성 언제 되나요?</p>
            <p className="text-[#5E6068]">
              영상 생성은{' '}
              <span className="font-bold text-main">요청 후 1일 내</span>에
              완료됩니다.{' '}
            </p>
          </div>
          <div>
            <p className="font-bold text-[20px]">
              생성된 영상 다운로드 어디서 하나요?
            </p>
            <p className="text-[#5E6068]">
              다운로드는{' '}
              <span
                className="text-href font-bold cursor-pointer underline"
                onClick={() => {
                  navigate('/my-videos')
                }}
              >
                사용 현황
              </span>{' '}
              페이지에서 생성 완료된 시점으로부터 30일간 가능합니다.
            </p>
          </div>
          <div>
            <p className="font-bold text-[20px]">
              영상 생성 개수 기준이 뭔가요?
            </p>
            <p className="text-[#5E6068]">
              플랜별 영상 생성 개수는 사용자가{` `}
              <span className="font-bold text-main">
                ‘생성 요청한 영상’ 개수
              </span>
              를 기준으로 카운팅 합니다.
            </p>
          </div>
          <div>
            <p className="font-bold text-[20px]">
              하루 3개 자동업로드 되면, 하루 3개만 생성할 수 있나요?
            </p>
            <p className="text-[#5E6068]">
              아니요! 하루 영상 생성 요청{' '}
              <span className="font-bold text-main">개수 제한은 없습니다.</span>{' '}
              프로,프리미엄 플랜은 자동 업로드 설정을 완료 했을시, 생성 요청 건
              모두{' '}
              <span className="font-bold text-main">
                요청 다음날부터 매일 하루 3개씩 순차적으로
              </span>{' '}
              오전 10시, 오후 2시, 오후 6시에{' '}
              <span className="font-bold text-main">
                예약 공개 상태로 자동 업로드
              </span>
              됩니다. 공개 일정 변경은 유튜브 스튜디오에서 자유롭게 가능합니다.
            </p>
          </div>
        </div>

        <GuideToUse />
        {!isRequest && <Spinner />}
        {essentialCheck && (
          <Modal isModal={essentialCheck}>
            <div className="bg-white md:w-[400px] w-[335px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  🙌 필수 정보를 확인해주세요!
                </p>
                <p className="text-[14px]">{errorText}</p>
                {/* <p className="text-red-500">{errorMessage}</p> */}
              </div>
            </div>
            <div className="md:w-[400px] w-[335px] bg-main h-[60px] border-b  border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px] font-bold"
                onClick={() => {
                  setEssentialCheck(false)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
        {saveSuccessModal && (
          <Modal isModal={saveSuccessModal}>
            <div className="bg-white md:w-[400px] w-[335px] h-[184px] p-[20px] border-t rounded-t-[40px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">요청 완료</p>
                <div>
                  <p className="text-[14px]">
                    {usage?.usedCount && (
                      <span className=" text-main font-bold">
                        총 {Number(usage?.usedCount) + 1}개
                      </span>
                    )}{' '}
                    /{' '}
                    <span className="text-[#8F929B]">
                      {usage?.totalLimit}개
                    </span>
                  </p>
                  <p className="text-[14px] text-main font-bold">
                    영상이 요청되었습니다.
                  </p>
                  <p className="text-[14px">
                    생성 현황은 <span className="font-bold">‘사용 현황'</span>{' '}
                    페이지에서 확인하세요.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:w-[400px] w-[335px] h-[60px]  rounded-b-[40px] flex justify-center items-center">
              <button
                className=" w-[50%] h-full font-bold bg-[#EEF1F7] border border-[#EEF1F7] rounded-bl-[40px]  text-[#5E6068]"
                onClick={() => {
                  setSaveSuccessModal(false)
                  refetch()
                  usageRefetch()
                  navigate('/my-videos')
                }}
              >
                사용 현황 페이지 가기
              </button>
              <button
                className="w-[50%] bg-main border border-main rounded-br-[40px] h-full text-white font-bold"
                onClick={() => {
                  const isTrialUser = userInfo?.userType === UserType.체험
                  const isProUser = userInfo?.userType === UserType.프로
                  const isPremiumUser = userInfo?.userType === UserType.프리미엄
                  const isUsageLimitReached =
                    Number(usage?.usedCount) + 1 === Number(usage?.totalLimit)

                  if (
                    (isProUser || isPremiumUser || isTrialUser) &&
                    isUsageLimitReached
                  ) {
                    setSaveSuccessModal(false)
                    refetch()
                    usageRefetch()
                    navigate('/my-videos')
                  } else {
                    setSaveSuccessModal(false)
                    //window.location.reload()
                    refetch()
                    usageRefetch()
                    handleInitData()
                  }
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}

        {isPlanPriceModal && (
          <PlanPriceModal
            isPlanPriceModal={isPlanPriceModal}
            setPlanPriceModal={setPlanPriceModal}
            userInfo={userInfo}
          />
        )}
        {mobileStatus && (
          <Modal isModal={mobileStatus}>
            <div className="bg-white w-[300px] h-[150px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  스마트폰으로 접속하셨습니다.
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className="text-main font-bold">PC사용을 권장합니다.</p>
                </div>
              </div>
            </div>
            <div className="w-[300px] bg-main h-[40px] border-b border-main  rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[330px]"
                onClick={() => {
                  setMobileStatus((prev) => !prev)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
        {isYoutube && (
          <Modal isModal={isYoutube}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  🚨 필수! 자동 업로드 설정을 해주세요!
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className="">
                    패스트컷 프로/프리미엄 플랜의 완벽한 사용을 위해
                    <br /> 내 유튜브 채널 현황을 파악하고 <br />
                    자동업로드가 될 수 있도록 설정 해주셔야 합니다 🙏
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[330px]"
                onClick={() => {
                  setIsYoutube((prev) => !prev)
                  navigate('/youtube-auth')
                }}
              >
                자동업로드 설정하러 가기
              </button>
            </div>
          </Modal>
        )}
        {isThumbnailModal && (
          <Modal isModal={isThumbnailModal}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  ✅ 썸네일 스타일을 변경하시겠어요?
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className=" text-[14px] ">
                    썸네일 스타일을 변경하시면
                    <br />
                    이미 선택하신 스타일의 썸네일에 입력된 내용은 삭제됩니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
              <button
                className=" w-[50%] h-full text-black bg-[#EEF1F7] rounded-bl-[20px] border-b border-[#EEF1F7] "
                onClick={() => {
                  setThumbnailCheckModal(false)
                }}
              >
                취소
              </button>
              <button
                className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main"
                onClick={() => {
                  setValue('thumbnailId', prevThumbnail.id)
                  removeLocalStorage(LOCAL_THUMBNAIL_INFO)
                  setValue('thumbnailInfo', {
                    channelName: '',
                    title1: '',
                    title2: '',
                    title3: '',
                    thumbnailFile: [],
                  })
                  handleThumbnail(prevThumbnail.index)
                }}
              >
                변경
              </button>
            </div>
          </Modal>
        )}
        {isMobileTModal && (
          <MobileThumbnail
            translatePlanName={translatePlanName}
            thumbnailList={thumbnailList ?? []}
            isMobileTModal={isMobileTModal}
            setMobileTModal={setMobileTModal}
            userInfo={userInfo}
            thumbnailType={thumbnailType}
            setThumbnailType={setThumbnailType}
            setValue={setValue}
            watch={watch}
            control={control}
            selectedThumbnail={selectedThumbnail}
            setSelectedThumbnail={setSelectedThumbnail}
          />
        )}
        {isGuideModal && (
          <GuideModal
            isGuideModal={isGuideModal}
            setGuideModal={setGuideModal}
            userInfo={userInfo}
          />
        )}
        {isFailWithShortModal && (
          <FailWithShortModal
            isFailWithShortModal={isFailWithShortModal}
            setFailWithShortModal={setFailWithShortModal}
            handleScroll={handleScroll}
          />
        )}

        <TransitionModal
          isTransitionModal={isTransitionModal}
          setTransitionModal={setTransitionModal}
          handleLoadedMetadata={handleLoadedMetadata}
          handlePlayButtonClick={handlePlayButtonClick}
          handleVideoEnded={handleVideoEnded}
          videoRefs={videoRefs}
          videoPlaying={videoPlaying}
          userInfo={userInfo}
          Controller={Controller}
          watch={watch}
          translatePlanName={translatePlanName}
          control={control}
          setValue={setValue}
          selectedTransitionIndex={selectedTransitionIndex}
          setSelectedTransitionIndex={setSelectedTransitionIndex}
          copyTransitionList={copyTransitionList}
          setCopyTransitionList={setCopyTransitionList}
        />
      </div>
    </>
  )
}

const NoneMemberTitle = () => {
  return (
    <div className="flex flex-col items-center w-[343px] md:w-full">
      <div className="flex items-center md:flex-row flex-col gap-1 mb-2 md:relative w-full justify-center">
        <h1 className="font-bold text-[26px] md:text-[40px]">
          클릭 한 번에 상품 리뷰 영상 생성!
        </h1>
        <a
          className="flex gap-2 items-center md:absolute md:right-0 md:top-[25%]"
          href="https://angelleague.notion.site/AI-15bba20e97f3809b8d33d7b08aaacd3f"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="assets/img/icon_youtube.png"
            alt="youtube"
            width={26}
            height={26}
          />{' '}
          <span className="font-bold">{`샘플 보기 >`}</span>
        </a>
      </div>
      <div className="">
        <p className="flex justify-center items-center gap-[4px]">
          <img
            src="assets/img/coupang_icon.png"
            alt="coupang"
            width={17.76}
            height={17.42}
          />
          <img
            src="assets/img/ali_icon.png"
            alt="ali"
            width={17.76}
            height={17.42}
          />
          어필리에이트 링크만 있으면,
        </p>
        {isMobile ? (
          <>
            <div className="flex flex-col items-center">
              <p className="">
                <span className='text-main font-bold"'>FASTCUT AI </span> 가
                나레이션, 편집, BGM 넣은
              </p>
              <p>영상을 내 채널에 등록까지 해줘요</p>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center">
              <p className="text-main font-bold">FASTCUT AI</p>
              <p>
                가 나레이션, 편집, BGM 넣은 영상을 내 채널에 등록까지 해줘요
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const MemberTitle = () => {
  return (
    <div className="flex flex-col items-center w-[343px] md:w-full md:relative">
      <h1 className="font-bold text-[32px] md:text-[40px]">상품 영상 만들기</h1>
      <a
        className="flex gap-2 items-center md:absolute md:right-0 md:top-[25%]"
        href="https://angelleague.notion.site/AI-15bba20e97f3809b8d33d7b08aaacd3f"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="assets/img/icon_youtube.png"
          alt="youtube"
          width={26}
          height={26}
        />{' '}
        <span className="font-bold">{`샘플 보기 >`}</span>
      </a>
    </div>
  )
}

const TextArea = (props: any) => {
  const { error, inputRef, isCheck, userCheck } = props

  const inputClass = classNames(
    'w-[343px] text-base md:w-full h-[123px]  rounded-[10px] p-[16px] bg-[#F6F8FB]',
    {
      'border border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return <textarea {...props} className={inputClass} ref={inputRef} />
}
