import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Me } from '../../../service/user'
import { BgmFactor } from '../../../service/list'
import { Control, Controller } from 'react-hook-form'
import { CircularCheckbox } from '../../CircularCheckbox'
import { FormValues } from '../../../utils/type'
import { PlanTag } from '../../planTag'
import { Modal } from '../../layout/Modal'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { ReactComponent as PlayIcon } from 'svg/play.svg'
import { CircularCheckboxWithPlayBtn } from 'components/CircularCheckboxWithPlayBtn'

interface BgmDisplayProps {
  watch: (name: 'bgmId', defaultValue?: number | undefined) => number
  setValue: (
    name: 'bgmId',
    value: number,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: Control<FormValues, any>
  userInfo: Me
  bgmList: BgmFactor[] | []
  translatePlanName?: (planName: string) => string
  bgmRefs: React.MutableRefObject<(HTMLAudioElement | null)[]>
  setBgmPlaying: Dispatch<SetStateAction<boolean[]>>
  bgmPlaying: boolean[]
  handleBgmPlayButtonClick: (status: boolean, index: number) => void
}

export const BgmDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  bgmList,
  bgmRefs,
  translatePlanName,
  setBgmPlaying,
  bgmPlaying,
  handleBgmPlayButtonClick,
}: PropsWithChildren<BgmDisplayProps>) => {
  const [isListModalOpen, setListModalOpen] = useState<boolean>(false)
  const findBgmNameById = () => {
    const [bgm] = bgmList.filter((el) => el.id === watch('bgmId'))
    return bgm?.name
  }
  const handleListModalOpen = () => {
    setListModalOpen((prev) => !prev)
  }

  useEffect(() => {
    if (isListModalOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isListModalOpen])

  return (
    <>
      <div className="bgm  mb-10">
        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-bold text-[18px] mb-4 ">배경음악</p>
          <p className="md:w-[630px] w-full h-[61px] rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <span className="">{findBgmNameById()}</span>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={handleListModalOpen}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>
      <Modal isModal={isListModalOpen}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10 px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p
                className={classNames('flex items-center', {
                  'justify-center': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold text-[24px] text-left">
                  배경음악
                </span>
                {/* <button
                  onClick={() => {
                    setListModalOpen((prev) => !prev)
                  }}
                  className=""
                >
                  <img
                    src="assets/img/Icon_x.png"
                    alt="icon_x"
                    height={40}
                    width={40}
                  />
                </button> */}
              </p>
            </div>
            <div className="overflow-auto ">
              <div className="grid grid-cols-1 md:grid-cols-2  gap-x-[22px] gap-y-[16px] md:overflow-auto md:h-[500px]  md:pb-4">
                {bgmList?.map((bgm, index) => {
                  return (
                    <>
                      <div
                        className="flex items-center  justify-between space-x-2  w-full md:w-full"
                        key={index}
                      >
                        <div className="flex">
                          <Controller
                            render={({ field }) => (
                              <CircularCheckboxWithPlayBtn
                                {...field}
                                id={index}
                                checked={watch('bgmId') === bgm.id}
                                onChange={() => setValue('bgmId', bgm.id)}
                                disabled={
                                  !userInfo ||
                                  userInfo?.userType === 'normalUser' ||
                                  userInfo?.userType === 'lightUser'
                                }
                                handleFn={handleBgmPlayButtonClick}
                                label={bgm.name}
                                className="text-left w-full"
                                index={index}
                                playing={bgmPlaying}
                              />
                            )}
                            name="bgmId"
                            control={control}
                          />

                          {translatePlanName && (
                            <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                              <PlanTag
                                planName={translatePlanName(bgm?.planName)}
                                className=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex gap-[12px]">
                          <audio
                            ref={(ref) => {
                              bgmRefs.current[index] = ref
                              if (ref) {
                                ref.onended = () => {
                                  const updatedBgmPlayingState = [...bgmPlaying]
                                  updatedBgmPlayingState[index] = false
                                  setBgmPlaying(updatedBgmPlayingState)
                                }
                              }
                            }}
                          >
                            <source src={bgm.path} type="audio/mpeg" />
                          </audio>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
              {isMobile && (
                <div className="pt-5 pb-20 w-[335px]">
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                    onClick={handleListModalOpen}
                    type="button"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
         before:content-[''] before:absolute before:top-[-10px] before:left-0 
         before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent z-50"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleListModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
