import classNames from 'classnames'
import { CircularCheckbox } from 'components/CircularCheckbox'
import { Modal } from 'components/layout/Modal'
import { PlanTag } from 'components/planTag'
import { ThumbnailImgComponent } from 'components/shortvideo/ThumbnailImgComponent'
import { VideoComponent } from 'components/video/VideoComponent'
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { StickerLocationType, StickerType } from 'service/list'
import { Me } from 'service/user'
import { FormValues } from 'utils/type'

interface CharacterDisplayProps {
  watch: UseFormWatch<FormValues>
  setValue: UseFormSetValue<FormValues>
  control: Control<FormValues, any>
  userInfo: Me
  translatePlanName: (planName: string) => string
  stickerList: StickerType[] | undefined
  stickerLocationList: StickerLocationType[] | undefined
}

export const StickerDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  translatePlanName,
  stickerList,
  stickerLocationList,
}: PropsWithChildren<CharacterDisplayProps>) => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [videoPlaying, setVideoPlaying] = useState<boolean[]>(
    new Array(stickerList?.length).fill(false)
  )
  const [selectedStickerIndex, setSelectedStickerIndex] = useState<number>(0)
  const [isOpenModal, setOpenModal] = useState(false)
  const [tab, setTab] = useState<'character' | 'item'>('character')
  const handleCharacterModal = () => {
    setOpenModal((prev) => !prev)
  }
  // 선택된 항목 가져오기

  const handleSelectedSticker = (behavior: boolean) => {
    if (videoRef.current && behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.muted = true
          videoRef.current!.currentTime = 0
          videoRef.current?.play().catch((error: any) => {
            console.warn('Playback failed:', error)
          })
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    } else if (videoRef.current && !behavior) {
      if (videoRef.current!.readyState >= 2) {
        try {
          videoRef.current!.currentTime = 1
          videoRef.current?.pause()
        } catch (e) {
          console.log(e, 'not play video index:')
        }
      }
    }
  }
  const handleVideoEnded = (index: number) => {
    const updatedPlayingState = [...videoPlaying]
    updatedPlayingState[index] = false
    setVideoPlaying(updatedPlayingState)
  }

  const handlePlayButtonClick = (index: number) => {
    const updatedPlayingState = videoPlaying.map((value, idx) =>
      idx === index ? true : false
    )

    setVideoPlaying(updatedPlayingState)
  }

  const stickerMap = useMemo(() => {
    if (stickerList) {
      const map = new Map<number, (typeof stickerList)[0]>()
      stickerList.forEach((item) => {
        map.set(item.id, item) // 숫자를 그대로 사용 가능
      })
      setVideoPlaying(new Array(stickerList?.length).fill(false))
      return map
    }
  }, [stickerList])

  const selectedStickerId = watch('stickerId')
  const selectedItem = stickerMap?.get(selectedStickerId) ?? 0

  const renderSelectedItem = () => {
    if (!selectedItem) return null

    return selectedItem.name === '없음' ? (
      <div
        className="flex md:flex-col relative w-[78px] h-[78px] justify-center outline outline-[4px] outline-main"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <button type="button" className="text-white font-bold z-50">
          효과 없음
        </button>
      </div>
    ) : (
      <div
        className={classNames(
          'flex w-[78px] h-[78px] relative outline outline-4 outline-main'
        )}
        onMouseEnter={!isMobile ? () => handleSelectedSticker(true) : undefined}
        onMouseLeave={
          !isMobile ? () => handleSelectedSticker(false) : undefined
        }
        onClick={isMobile ? () => handleSelectedSticker(true) : undefined}
      >
        {videoRef ? (
          <video
            ref={videoRef}
            src={selectedItem.path}
            playsInline
            width={78}
            loop
            autoPlay
            muted
            height={78}
            preload="auto"
          />
        ) : (
          <img
            src={selectedItem.imagePath}
            className="w-[78px] h-[78px]"
            loading="lazy"
            alt=""
          />
        )}
        <div className="absolute w-[78px] flex  bottom-0 left-[25%]">
          <span
            className="font-bold text-white text-[14px] font-[Pretendard]"
            style={{ WebkitTextStroke: '0.5px black' }}
          >
            선택됨
          </span>
        </div>
      </div>
    )
  }

  const renderStickerItem = (sticker: StickerType, index: number) => {
    const isSelected = watch('stickerId') === sticker.id

    return (
      <div
        key={index}
        className="flex md:flex-col relative"
        onMouseEnter={
          !isMobile ? () => handlePlayButtonClick(index) : undefined
        }
        onMouseLeave={!isMobile ? () => handleVideoEnded(index) : undefined}
        onClick={isMobile ? () => handlePlayButtonClick(index) : undefined}
      >
        {sticker?.name !== '없음' ? (
          <VideoComponent
            videoRefs={videoRefs}
            src={sticker.path}
            className={classNames({
              'outline outline-[4px] outline-main': isSelected,
            })}
            imagePath={sticker.imagePath}
            index={index}
            selected={isSelected}
            videoPlaying={videoPlaying}
            width="w-[78px]"
          />
        ) : (
          <div
            className={classNames(
              'flex  md:flex-col  relative w-[78px] h-[78px] justify-center',
              {
                ' outline outline-[4px] outline-main':
                  sticker.name === '없음' && sticker.id === watch('stickerId'),
              }
            )}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={() => {
              if (sticker?.usable) {
                setSelectedStickerIndex(index)
                setValue('stickerId', sticker.id)
              }
            }}
          >
            <button
              type="button"
              className={classNames(
                'text-white font-bold z-50 w-[78px] h-[78px]'
              )}
            >
              효과 없음
            </button>
          </div>
        )}
        {index === (isMobile ? 4 : 7) ? (
          <div
            className={`absolute w-[78px] h-[78px] flex items-center justify-center $ `}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenModal((prev) => !prev)
              }}
              className=" text-white font-bold z-50"
            >
              {stickerList && stickerList?.length - (isMobile ? 4 : 7)}개 <br />{' '}
              더보기
            </button>
          </div>
        ) : (
          <div
            className={classNames('absolute w-[78px] h-[78px] flex', {
              'bg-opacity-50': isSelected,
            })}
            style={{
              backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.5)' : undefined,
            }}
            onClick={() => {
              if (sticker?.usable) {
                setSelectedStickerIndex(index)
                setValue('stickerId', sticker.id)
              }
            }}
          >
            <div className="flex items-start h-full pt-1 mt-[6px] ml-[6px]">
              <PlanTag planName={translatePlanName(sticker.planName)} />
            </div>
            <Controller
              name="stickerId"
              control={control}
              render={({ field }: any) => (
                <label className="relative flex w-full cursor-pointer items-end justify-end mr-1 mb-1">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isSelected}
                    disabled={!sticker.usable}
                    onChange={() => {
                      if (sticker?.usable) {
                        setSelectedStickerIndex(index)
                        setValue('stickerId', sticker.id)
                      }
                    }}
                  />
                  <div
                    className={classNames(
                      'w-[20px] h-[20px] rounded-full border-2 flex items-center justify-center',
                      {
                        'bg-white border-main': isSelected,
                        'bg-white': !isSelected,
                      }
                    )}
                  >
                    {isSelected && (
                      <div className="w-3 h-3 bg-main rounded-full"></div>
                    )}
                  </div>
                </label>
              )}
            />
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (isOpenModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpenModal])

  useEffect(() => {
    const stickerId = watch('stickerId')
    if (stickerList) {
      const [sticker] = stickerList.filter((el) => el?.id === stickerId)
      if (sticker?.name === '없음') {
        setTab('character')
      } else {
        setTab(sticker?.type as 'character' | 'item')
      }
    }
  }, [stickerList, isOpenModal])
  return (
    <>
      {' '}
      <div className="likeCommentWatch  my-[40px]">
        <div className="flex items-center mb-4 gap-4  ">
          <p className="font-bold text-[18px]">스티커</p>
          <p className="flex  items-center gap-1 ">
            <span
              className="font-bold text-white text-[11px] px-1 py-[2px] rounded-[4px]"
              style={{
                background: `linear-gradient(to right,#6095FF,#00CBB3)`,
              }}
            >
              UPDATE
            </span>
            {!isMobile && (
              <span className="text-[14px] text-[#5E6068]">
                나에게 맞는 스티커를 선택해보세요!
              </span>
            )}
          </p>
        </div>{' '}
        <div
          className={classNames('flex gap-2', {
            'flex-col': isMobile,
            'flex-row items-center ': !isMobile,
          })}
        >
          {renderSelectedItem()}
          <div
            className={classNames('', {
              'flex flex-row  justify-between bg-[#EEF1F7] h-[102px]  p-3 rounded-[10px] gap-3 w-[650px]':
                !isMobile,
              'grid grid-cols-4 gap-x-1 gap-y-5 bg-[#EEF1F7]  px-[10px]  py-5 rounded-[10px] ':
                isMobile,
            })}
          >
            {' '}
            {stickerList?.map((sticker, index) => {
              if (sticker.type === 'character' && index <= (isMobile ? 4 : 7))
                return <>{renderStickerItem(sticker, index)}</>

              return <> </>
            })}
          </div>
        </div>
      </div>
      <Modal isModal={isOpenModal}>
        <div className="md:h-screen h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8  flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10 px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4  ">
              <p
                className={classNames('flex  flex-col gap-4', {
                  '': isMobile,
                })}
              >
                <span className="font-bold text-[24px] md:text-left text-center">
                  스티커
                </span>
                <span className="md:text-left">
                  롱폼 영상 내에 위치시켜 생동감을 줄 수 있어요!
                </span>
              </p>
            </div>
            {/* 위치 */}
            <div className="flex flex-col gap-4  overflow-auto md:pb-4 md:h-[450px]">
              <div className="w-full flex flex-col gap-4 ">
                <p className="text-left text-[18px] font-bold">위치</p>
                <div className="flex gap-3 flex-wrap">
                  {stickerLocationList?.map((el, index) => (
                    <>
                      <div
                        className="flex flex-col cursor-pointer"
                        onClick={() => {
                          setValue('stickerLocation', el.value)
                        }}
                      >
                        <ThumbnailImgComponent thumbnail={el} index={false} />

                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={index}
                              checked={watch('stickerLocation') === el.value}
                              label={el.name}
                              onChange={() => {
                                setValue('stickerLocation', el.value)
                              }}
                            />
                          )}
                          name="stickerLocation"
                          control={control}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </div>
              {/* 스타일 */}
              <div className="w-full flex flex-col gap-4 ">
                <p className="text-left text-[18px] font-bold">스타일</p>
                {/* tab */}
                <div className="flex bg-[#EEF1F7] h-[40px] rounded-[10px] items-center text-[18px] px-1 justify-between">
                  <button
                    className={classNames('w-[360px] h-[30px]', {
                      'bg-[#FFFFFF] rounded-[6px]': tab === 'character',
                      '': tab === 'item',
                    })}
                    type="button"
                    onClick={() => {
                      setTab('character')
                    }}
                  >
                    캐릭터
                  </button>
                  <button
                    className={classNames('w-[360px] h-[30px]', {
                      'bg-[#FFFFFF] rounded-[6px]': tab === 'item',
                      '': tab === 'character',
                    })}
                    type="button"
                    onClick={() => {
                      setTab('item')
                    }}
                  >
                    아이템
                  </button>
                </div>
                <p className="text-left font-bold  ">
                  총{' '}
                  <span className=" text-main">
                    {stickerList?.filter((el) => el.type === tab).length ?? +1}
                    개
                  </span>{' '}
                  <span className="ml-4 text-[14px] text-error font-normal">
                    신규 기능 할인 이벤트중
                  </span>
                </p>
                {tab === 'character' ? (
                  <>
                    <div className="grid md:grid-cols-8 grid-cols-4 gap-x-2 gap-y-4 px-2">
                      {stickerList?.map((sticker, index) => {
                        if (sticker?.name === '없음') {
                          return (
                            <div
                              className="flex  md:flex-col  relative w-[78px] h-[78px] justify-center"
                              key={index}
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              }}
                              onClick={() => {
                                if (sticker?.usable) {
                                  setSelectedStickerIndex(index)
                                  setValue('stickerId', sticker.id)
                                }
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  handleVideoEnded(index)
                                }}
                                className=" text-white font-bold z-50"
                              >
                                효과 없음
                              </button>
                              <div
                                className={classNames(
                                  'absolute w-[78px] h-[78px] flex  justify-center',
                                  {
                                    'outline-[4px] outline outline-main':
                                      sticker.id === watch('stickerId'),
                                  }
                                )}
                              >
                                <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                                  <PlanTag
                                    planName={translatePlanName(
                                      sticker?.planName
                                    )}
                                    className=""
                                  />
                                </div>
                                <Controller
                                  render={({ field }: any) => (
                                    <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                      <input
                                        type="checkbox"
                                        className="sr-only"
                                        checked={
                                          watch('stickerId') === sticker.id
                                        }
                                        disabled={sticker?.usable}
                                        onChange={() => {
                                          if (sticker?.usable) {
                                            setSelectedStickerIndex(index)
                                            setValue('stickerId', sticker.id)
                                          }
                                        }}
                                      />
                                      <div
                                        className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                          watch('stickerId') === sticker.id
                                            ? 'bg-white border-main'
                                            : 'bg-white'
                                        }`}
                                      >
                                        {watch('stickerId') === sticker.id && (
                                          <div className="w-3 h-3 bg-main rounded-full"></div>
                                        )}
                                      </div>
                                    </label>
                                  )}
                                  name="stickerId"
                                  control={control}
                                />
                              </div>
                            </div>
                          )
                        }

                        if (sticker.type === 'character') {
                          return (
                            <>
                              <div
                                className={`flex  md:flex-col  relative w-[78px] h-[78px]  video_${index}`}
                                key={index}
                                onMouseEnter={
                                  !isMobile
                                    ? () => handlePlayButtonClick(index)
                                    : undefined
                                }
                                onMouseLeave={
                                  !isMobile
                                    ? () => handleVideoEnded(index)
                                    : undefined
                                }
                                onClick={
                                  isMobile
                                    ? () => handlePlayButtonClick(index)
                                    : undefined
                                }
                              >
                                <VideoComponent
                                  videoRefs={videoRefs}
                                  src={sticker.path}
                                  className={classNames({
                                    ' outline outline-[4px] outline-main':
                                      sticker.id === watch('stickerId'),
                                  })}
                                  imagePath={sticker.imagePath}
                                  index={index}
                                  selected={true}
                                  videoPlaying={videoPlaying}
                                  width="w-[78px]"
                                />

                                <>
                                  <div
                                    style={{
                                      backgroundColor:
                                        watch('stickerId') === sticker.id
                                          ? 'rgba(0, 0, 0, 0.5)'
                                          : '',
                                    }}
                                    className={classNames(
                                      'absolute w-[78px] h-[78px] flex'
                                    )}
                                    onClick={() => {
                                      if (sticker?.usable) {
                                        setSelectedStickerIndex(index)
                                        setValue('stickerId', sticker.id)
                                      }
                                    }}
                                  >
                                    <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                                      <PlanTag
                                        planName={translatePlanName(
                                          sticker?.planName
                                        )}
                                        className=""
                                      />
                                    </div>
                                    <Controller
                                      render={({ field }: any) => (
                                        <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                          <input
                                            type="checkbox"
                                            className="sr-only"
                                            checked={
                                              watch('stickerId') === sticker.id
                                            }
                                            disabled={sticker?.usable}
                                            onChange={() => {
                                              if (sticker?.usable) {
                                                setSelectedStickerIndex(index)
                                                setValue(
                                                  'stickerId',
                                                  sticker.id
                                                )
                                              }
                                            }}
                                          />
                                          <div
                                            className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                              watch('stickerId') === sticker.id
                                                ? 'bg-white border-main'
                                                : 'bg-white'
                                            }`}
                                          >
                                            {watch('stickerId') ===
                                              sticker.id && (
                                              <div className="w-3 h-3 bg-main rounded-full"></div>
                                            )}
                                          </div>
                                        </label>
                                      )}
                                      name="stickerId"
                                      control={control}
                                    />
                                  </div>
                                </>
                              </div>
                            </>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    {/* 아이템 구간 */}
                    <div className="grid md:grid-cols-8 grid-cols-4  gap-x-2 gap-y-4 px-2">
                      {stickerList?.map((sticker, index) => {
                        if (sticker?.name === '없음') {
                          return (
                            <div
                              className="flex  md:flex-col  relative w-[78px] h-[78px] justify-center"
                              key={index}
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              }}
                              onClick={() => {
                                if (sticker?.usable) {
                                  setSelectedStickerIndex(index)
                                  setValue('stickerId', sticker.id)
                                }
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  handleVideoEnded(index)
                                }}
                                className=" text-white font-bold z-50"
                              >
                                효과 없음
                              </button>
                              <div
                                className={classNames(
                                  'absolute w-[78px] h-[78px] flex  justify-center',
                                  {
                                    'outline-[4px] outline outline-main':
                                      sticker.id === watch('stickerId'),
                                  }
                                )}
                              >
                                <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                                  <PlanTag
                                    planName={translatePlanName(
                                      sticker?.planName
                                    )}
                                    className=""
                                  />
                                </div>
                                <Controller
                                  render={({ field }: any) => (
                                    <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                      <input
                                        type="checkbox"
                                        className="sr-only"
                                        checked={
                                          watch('stickerId') === sticker.id
                                        }
                                        disabled={sticker?.usable}
                                        onChange={() => {
                                          if (sticker?.usable) {
                                            setSelectedStickerIndex(index)
                                            setValue('stickerId', sticker.id)
                                          }
                                        }}
                                      />
                                      <div
                                        className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                          watch('stickerId') === sticker.id
                                            ? 'bg-white border-main'
                                            : 'bg-white'
                                        }`}
                                      >
                                        {watch('stickerId') === sticker.id && (
                                          <div className="w-3 h-3 bg-main rounded-full"></div>
                                        )}
                                      </div>
                                    </label>
                                  )}
                                  name="stickerId"
                                  control={control}
                                />
                              </div>
                            </div>
                          )
                        }

                        if (sticker.type !== 'character') {
                          return (
                            <>
                              <div
                                className={`flex  md:flex-col  relative w-[78px] h-[78px]  video_${index}`}
                                key={index}
                                onMouseEnter={
                                  !isMobile
                                    ? () => handlePlayButtonClick(index)
                                    : undefined
                                }
                                onMouseLeave={
                                  !isMobile
                                    ? () => handleVideoEnded(index)
                                    : undefined
                                }
                                onClick={
                                  isMobile
                                    ? () => handlePlayButtonClick(index)
                                    : undefined
                                }
                              >
                                <VideoComponent
                                  videoRefs={videoRefs}
                                  src={sticker.path}
                                  className={classNames({
                                    ' outline outline-[4px] outline-main':
                                      sticker.id === watch('stickerId'),
                                  })}
                                  imagePath={sticker.imagePath}
                                  index={index}
                                  selected={true}
                                  videoPlaying={videoPlaying}
                                  width="w-[78px]"
                                />

                                <>
                                  <div
                                    style={{
                                      backgroundColor:
                                        watch('stickerId') === sticker.id
                                          ? 'rgba(0, 0, 0, 0.5)'
                                          : '',
                                    }}
                                    className={classNames(
                                      'absolute w-[78px] h-[78px] flex'
                                    )}
                                    onClick={() => {
                                      if (sticker?.usable) {
                                        setSelectedStickerIndex(index)
                                        setValue('stickerId', sticker.id)
                                      }
                                    }}
                                  >
                                    <div className="flex items-start h-full  pt-1   mt-[6px] ml-[6px] ">
                                      <PlanTag
                                        planName={translatePlanName(
                                          sticker?.planName
                                        )}
                                        className=""
                                      />
                                    </div>
                                    <Controller
                                      render={({ field }: any) => (
                                        <label className="relative flex w-full  cursor-pointer  items-end  justify-end mr-1 mb-1 ">
                                          <input
                                            type="checkbox"
                                            className="sr-only"
                                            checked={
                                              watch('stickerId') === sticker.id
                                            }
                                            disabled={sticker?.usable}
                                            onChange={() => {
                                              if (sticker?.usable) {
                                                setSelectedStickerIndex(index)
                                                setValue(
                                                  'stickerId',
                                                  sticker.id
                                                )
                                              }
                                            }}
                                          />
                                          <div
                                            className={`w-[20px] h-[20px] rounded-full border-2 bg-main flex items-center justify-center ${
                                              watch('stickerId') === sticker.id
                                                ? 'bg-white border-main'
                                                : 'bg-white'
                                            }`}
                                          >
                                            {watch('stickerId') ===
                                              sticker.id && (
                                              <div className="w-3 h-3 bg-main rounded-full"></div>
                                            )}
                                          </div>
                                        </label>
                                      )}
                                      name="stickerId"
                                      control={control}
                                    />
                                  </div>
                                </>
                              </div>
                            </>
                          )
                        }
                        return <></>
                      })}{' '}
                    </div>
                  </>
                )}
              </div>
              {isMobile && (
                <div className="pt-5 pb-20 w-[335px]">
                  <button
                    className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                    onClick={handleCharacterModal}
                    type="button"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
                           before:content-[''] before:absolute before:top-[-10px] before:left-0 
                           before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent z-50"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleCharacterModal}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
