import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Me } from '../../../service/user'
import {
  BgmFactor,
  VoiceGroupFactor,
  VoiceTagType,
} from '../../../service/list'
import { Control, Controller } from 'react-hook-form'
import { CircularCheckbox } from '../../CircularCheckbox'
import { FormValues } from '../../../utils/type'
import { PlanTag } from '../../planTag'
import { Modal } from '../../layout/Modal'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { CircularCheckboxWithPlayBtn } from 'components/CircularCheckboxWithPlayBtn'
import VoiceTooltip from 'components/shortvideo/VoiceTooltip'

interface ShortVoiceDisplayProps {
  watch: (name: 'voiceId', defaultValue?: number | undefined) => number
  setValue: (
    name: 'voiceId',
    value: number,
    options?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
          shouldTouch: boolean
        }>
      | undefined
  ) => void
  control: any
  userInfo: Me
  voiceList: VoiceGroupFactor[] | []
  translatePlanName?: (planName: string) => string
  voiceRefs: React.MutableRefObject<(HTMLAudioElement | null)[]>
  setVoicePlaying: Dispatch<SetStateAction<boolean[]>>
  voicePlaying: boolean[]
  handleVoicePlayButtonClick: (status: boolean, index: number) => void
  voiceTags: VoiceTagType | undefined
  selectedVoiceId: number
  setVoiceTooltip: Dispatch<SetStateAction<boolean>>
  voiceTooltip: boolean
}

export const ShortVoiceDisplay = ({
  watch,
  setValue,
  control,
  userInfo,
  voiceList,
  voiceRefs,
  translatePlanName,
  setVoicePlaying,
  voicePlaying,
  handleVoicePlayButtonClick,
  voiceTags,
  selectedVoiceId,
  setVoiceTooltip,
  voiceTooltip,
}: PropsWithChildren<ShortVoiceDisplayProps>) => {
  const [isListModalOpen, setListModalOpen] = useState<boolean>(false)
  const [isGenderSelectBoxOpen, setGenderSelectBoxOpen] =
    useState<boolean>(false)
  const [isAgeSelectBoxOpen, setAgeSelectBoxOpen] = useState<boolean>(false)

  const [genderWords, setGenderWords] = useState<string[]>(
    voiceTags?.genders ?? ['남성', '여성']
  )

  const [ageWords, setAgeWords] = useState<string[]>(
    voiceTags?.ages ?? ['청년', '중년 이상', '청소년']
  )
  const [toneName, setToneName] = useState<string>('')
  const [voiceName, setVoiceName] = useState<string>('')
  const [genderValue, setGenderValue] = useState<string>('')
  const [ageValue, setAgeValue] = useState<string>('')
  const [voiceListValue, setVoiceListValue] = useState<VoiceGroupFactor[] | []>(
    []
  )

  const handleGenderChange = (gender: string) => {
    setGenderWords(
      (prev) =>
        prev.includes(gender)
          ? prev.filter((item) => item !== gender) // 체크 해제
          : [...prev, gender] // 체크 선택
    )
  }
  const handleAgeChange = (age: string) => {
    setAgeWords(
      (prev) =>
        prev.includes(age)
          ? prev.filter((item) => item !== age) // 체크 해제
          : [...prev, age] // 체크 선택
    )
  }

  const findBgmNameById = () => {
    const [bgm] = voiceList.filter((el) => el.id === watch('voiceId'))
    return bgm?.name
  }
  const handleListModalOpen = () => {
    setListModalOpen((prev) => !prev)
  }

  useEffect(() => {
    if (voiceList) {
      //voice아이디를 가지고 list에서 찾아서 gender와 age를 셋팅해야함

      const [voice] = voiceList.filter((el) => el.id === selectedVoiceId)
      if (voice) {
        setVoiceName(voice?.name)
        setAgeValue(voice.age ?? '')
        setGenderValue(voice.gender ?? '')
      }
    }
  }, [voiceList, selectedVoiceId])

  useEffect(() => {
    voiceRefs.current.forEach((audioRef, index) => {
      if (audioRef && !voicePlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [voicePlaying])

  useEffect(() => {
    if (voiceList) {
      let filter = voiceList

      // 1차: 성별 필터링
      if (genderWords.length > 0) {
        filter = filter.filter((el) => genderWords.includes(el?.gender ?? ''))
      } else {
        filter = []
      }

      // 2차: 연령 필터링
      if (ageWords.length > 0) {
        filter = filter.filter((el) => ageWords.includes(el.age ?? ''))
      } else {
        filter = []
      }

      // 상태 업데이트
      const prevVoiceId = watch('voiceId')

      setVoiceListValue(filter)
      setVoicePlaying(new Array(filter.length).fill(false))

      // voiceId 설정 (사용 가능한 첫 번째 항목 또는 이전 ID 유지)

      // setValue('voiceId', filter[0]?.usable ? filter[0].id : prevVoiceId)
    }
  }, [genderWords, ageWords, voiceList])
  useEffect(() => {
    if (isListModalOpen) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isListModalOpen])

  return (
    <>
      <div className="bgm  mb-10">
        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <div>
            {' '}
            <p className="text-[18px]  flex items-center gap-1 relative md:mb-0 mb-4">
              <span className="font-bold">목소리</span>
              <VoiceTooltip
                voiceList={voiceList}
                setVoiceTooltip={setVoiceTooltip}
                voiceTooltip={voiceTooltip}
              />
              <span
                className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                style={{
                  background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                }}
              >
                SALE
              </span>
            </p>
          </div>
          <p className="md:w-[580px] w-full h-[61px] rounded-[10px] bg-[#F6F8FB] px-4 py-5 flex items-center justify-between font-bold">
            <span className="">{findBgmNameById()}</span>
            <button
              className="px-2 py-[6px] bg-[#2E343D] text-[white] rounded-[4px] text-[14px]"
              onClick={handleListModalOpen}
              type="button"
            >
              선택 {'>'}
            </button>
          </p>
        </div>
      </div>

      <Modal isModal={isListModalOpen}>
        <div className="h-full w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
          <div
            className={classNames('', {
              'bg-white md:w-[816px] h-auto  shadow-main rounded-t-[20px] px-8 pt-8 flex flex-col   gap-8':
                !isMobile,
              'bg-white w-full h-full  shadow-main   flex flex-col   gap-8 pt-10  px-4  ':
                isMobile,
            })}
          >
            <div className="flex flex-col  gap-4   ">
              <p
                className={classNames('flex items-center', {
                  'justify-center': isMobile,
                  'justify-between': !isMobile,
                })}
              >
                <span className="font-bold text-[24px] text-left">
                  목소리 선택
                </span>

                {/* <button
                  onClick={() => {
                    setListModalOpen((prev) => !prev)
                  }}
                  className=""
                >
                  <img
                    src="assets/img/Icon_x.png"
                    alt="icon_x"
                    height={40}
                    width={40}
                  />
                </button> */}
              </p>
              <p className="w-full text-left">
                AI 생성 대본 컨셉에 맞춰 어울리는 나레이션 말투와 목소리를
                선택해보세요!
              </p>
            </div>
            <div className="tts flex flex-col items-start overflow-auto md:scrollbar-visible  md:h-[438px]">
              <div
                className={classNames('', {
                  'select_box flex gap-4 mb-[16px]': !isMobile,
                  'select_box flex flex-col gap-4 mb-[16px]': isMobile,
                })}
              >
                <div className="flex items-center gap-2">
                  <span>성별</span>{' '}
                  <div
                    className="relative"
                    onMouseLeave={() => {
                      setGenderSelectBoxOpen(false)
                    }}
                  >
                    <button
                      className={classNames('', {
                        'w-[120px] h-[33px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-[14px] text-left':
                          !isMobile,
                        'w-[120px] h-[51px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-left':
                          isMobile,
                      })}
                      value={genderValue}
                      type="button"
                      onClick={() => {
                        setGenderSelectBoxOpen((prev) => !prev)
                      }}
                    >
                      {' '}
                      {genderWords.length > 0 ? genderWords.join(', ') : '성별'}
                    </button>
                    <img
                      src="assets/img/chevron-down.png"
                      className={classNames('', {
                        'w-[16px] h-[16px] absolute right-2 bottom-2':
                          !isMobile,
                        'w-[16px] h-[16px] absolute right-2 bottom-4': isMobile,
                      })}
                      alt=""
                      onClick={() => {
                        setGenderSelectBoxOpen((prev) => !prev)
                      }}
                    />
                    {isGenderSelectBoxOpen && (
                      <div className=" absolute bg-white z-50 w-[120px] h-[80px] shadow-main flex flex-col px-3 py-1  gap-2 justify-center rounded-[10px]">
                        {voiceTags?.genders.map((gender, index) => {
                          return (
                            <>
                              <div className="flex items-center gap-2">
                                <input
                                  id={`gender-${index}`} // 고유한 id
                                  type="checkbox"
                                  className="w-[20px] h-[20px]"
                                  value={gender}
                                  checked={genderWords.includes(gender)}
                                  onChange={() => handleGenderChange(gender)}
                                />
                                <label htmlFor={`gender-${index}`}>
                                  {gender}
                                </label>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={classNames('flex items-center gap-2', {
                    '': !isMobile,
                  })}
                >
                  <span>연령</span>{' '}
                  <div
                    className="relative"
                    onMouseLeave={() => {
                      setAgeSelectBoxOpen(false)
                    }}
                  >
                    <button
                      className={classNames('', {
                        ' w-[179px] h-[33px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500] text-[14px] text-left':
                          !isMobile,
                        'w-[197px] h-[51px] px-4 py-1 border-[1px] border-[#D4D9DF] rounded-[6px] font-[500]  text-left':
                          isMobile,
                      })}
                      value={ageValue}
                      type="button"
                      onClick={() => {
                        setAgeSelectBoxOpen((prev) => !prev)
                      }}
                    >
                      {' '}
                      {ageWords.length > 0 ? ageWords.join(', ') : '연령'}
                    </button>
                    <img
                      src="assets/img/chevron-down.png"
                      className={classNames('', {
                        'w-[16px] h-[16px] absolute right-2 bottom-2':
                          !isMobile,
                        'w-[16px] h-[16px] absolute right-2 bottom-4': isMobile,
                      })}
                      alt=""
                      onClick={() => {
                        setAgeSelectBoxOpen((prev) => !prev)
                      }}
                    />{' '}
                    {isAgeSelectBoxOpen && (
                      <div className=" absolute bg-white z-50 w-[120px] h-[140px] shadow-main flex flex-col px-3 py-1  gap-2 justify-center rounded-[10px] right-0">
                        {voiceTags?.ages.map((age, index) => {
                          return (
                            <label className="w-full flex justify-start gap-2 items-center">
                              <input
                                id={`age-${index}`} // 고유한 id
                                type="checkbox"
                                className="w-[20px] h-[20px]"
                                value={age}
                                checked={ageWords.includes(age)}
                                onChange={() => handleAgeChange(age)}
                              />
                              <span>{age}</span>
                            </label>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="grid grid-cols-2 md:grid-cols-3 md:gap-x-[53.67px] gap-x-[40px] gap-y-[16px] md:pb-4 ">
                  {voiceListValue?.map((voice, index) => {
                    return (
                      <>
                        <div
                          className="md:w-[193px] w-full flex gap-[8px] justify-between md:items-center"
                          key={`voice_${voice.name}_${index}`}
                        >
                          <div className="flex">
                            <Controller
                              render={({ field }) => (
                                <CircularCheckboxWithPlayBtn
                                  {...field}
                                  id={index}
                                  index={index}
                                  playing={voicePlaying}
                                  handleFn={handleVoicePlayButtonClick}
                                  checked={watch('voiceId') === voice.id}
                                  onChange={() => {
                                    setValue('voiceId', voice.id)
                                    setVoiceName(voice.name)
                                  }}
                                  label={voice.name}
                                />
                              )}
                              name="voiceId"
                              control={control}
                            />
                            {new Array(voice.cashRate).fill(0).map((el) => (
                              <>
                                {' '}
                                <img
                                  src="assets/img/cash.png"
                                  alt="cash"
                                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                />
                              </>
                            ))}
                          </div>

                          <div className="flex gap-[12px]">
                            <button type="button">
                              <audio
                                ref={(ref) => {
                                  voiceRefs.current[index] = ref
                                  if (ref) {
                                    ref.onended = () => {
                                      const updatedPlayingState = [
                                        ...voicePlaying,
                                      ]
                                      updatedPlayingState[index] = false
                                      setVoicePlaying(updatedPlayingState)
                                    }
                                  }
                                }}
                              >
                                <source
                                  src={voice.shortPath}
                                  type="audio/mpeg"
                                />
                              </audio>
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                {isMobile && (
                  <div className=" flex w-full pt-5  pb-20 md:pb-0">
                    <button
                      className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                      onClick={handleListModalOpen}
                      type="button"
                    >
                      저장
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isMobile && (
            <div
              className="relative p-8 shadow-main w-full md:w-[816px] bg-white rounded-b-[20px] 
                before:content-[''] before:absolute before:top-[-10px] before:left-0 
                before:w-full before:h-[10px] before:bg-gradient-to-t before:from-black/10 before:to-transparent"
            >
              <button
                className="bg-main rounded-full w-full text-white font-bold h-[60px]"
                onClick={handleListModalOpen}
                type="button"
              >
                저장
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
